const SearchCheck = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.1251 20 15.0781 19.2635 16.6178 18.0318L20.2931 21.7071C20.6836 22.0976 21.3168 22.0976 21.7073 21.7071C22.0978 21.3165 22.0978 20.6834 21.7073 20.2928L18.032 16.6175C19.2636 15.0779 20 13.125 20 11C20 6.02944 15.9706 2 11 2ZM14.7071 9.70711C15.0976 9.31658 15.0976 8.68342 14.7071 8.29289C14.3166 7.90237 13.6834 7.90237 13.2929 8.29289L10 11.5858L8.70711 10.2929C8.31658 9.90237 7.68342 9.90237 7.29289 10.2929C6.90237 10.6834 6.90237 11.3166 7.29289 11.7071L9.29289 13.7071C9.68342 14.0976 10.3166 14.0976 10.7071 13.7071L14.7071 9.70711Z"
        fill="#FFA02F"
      />
    </svg>
  );
};

export default SearchCheck;
