import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import CalendarContained from 'assets/icons/CalendarConatined';
import Document from 'assets/icons/Document';
import { MedicalCase } from 'assets/icons/MedicalCase';
import UnassignWorker from 'assets/icons/UnassignWorker';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getUserFIO } from 'utils/helpers/userFullName';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useStore } from 'store';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import clsx from 'clsx';
import Header from './components/Header';
import SkeletonLayout from 'components/table/Skeleton';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
  loading?: boolean;
}

const Performers = (props: Props) => {
  const { data, loading } = props;
  const { facilityId } = useParams();

  const { setShowStopCooperationSidebar, setUser, setSelectedRow, setSelectedDay, isCalendar } =
    useFacilityReportStore();
  const { setCreateScheduleMenu } = useStore();

  const isMedBookValid = (requisite: any) => {
    if (requisite?.medicalAttestationEnd && dayjs(requisite?.medicalAttestationEnd).isValid()) {
      return (
        dayjs(requisite?.medicalAttestationEnd).isAfter(dayjs()) ||
        dayjs(requisite?.medicalAttestationEnd).isSame(dayjs())
      );
    }
    return !!requisite?.medicalInvoiceIssueDate && dayjs(requisite?.medicalInvoiceIssueDate).isValid();
  };

  const getDocumentIconTitle = ({ taxPatnerInfo, cardNumber, registrationState, contracts }: any) => {
    return (
      <div>
        {registrationState !== 'FULL_REGISTRATION' && <div>• Не пройдена полная регистрация</div>}
        {taxPatnerInfo !== 'CONFIRMED' && <div>• Не подключен мой налог</div>}
        {!cardNumber && <div>• Не указаны платежные данные</div>}
        {contracts?.some((contract: any) => contract.status === 'Unsigned') && (
          <div>• Есть неподписанные документы</div>
        )}
      </div>
    );
  };

  const groupedData = data?.reduce(
    (acc, item) => {
      const professionId = item.workPost?.position?.id;

      const key = `${professionId}-${item.user.id}`;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Record<string, typeof data>,
  );

  const isUserAlreadyUnassigned = (userItem: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'][number]) => {
    if (!userItem.user.userExperience) return false;

    return !userItem.user.userExperience.some(
      exp =>
        exp.facilityId === facilityId && exp.positionId === userItem.workPost.position?.id && exp.unassignAt === null,
    );
  };

  const performersColumns = [
    {
      name: 'name',
      className:
        'w-[200px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
      style: { width: 200, minWidth: 200 },
      Header: 'ФИО',
      sorted: false,
    },
    {
      name: 'position',
      className:
        'w-[150px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
      style: { width: 150, minWidth: 150 },
      Header: 'Должность',
      sorted: false,
    },
    {
      name: 'actions',
      className:
        'w-[110px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
      style: { width: 110, minWidth: 110 },
      Header: 'Действия',
      sorted: false,
    },
  ];

  return (
    <div className="h-full border-r-[1px] border-smena_gray-60">
      <Header />

      {loading && <SkeletonLayout columns={performersColumns} rows={5} />}

      <div>
        {groupedData &&
          Object.entries(groupedData).map(([, users], index) => (
            <React.Fragment key={index}>
              {users.map((userItem, userIndex) => {
                const isLastInGroup = userIndex === users.length - 1;
                const isUnassigned = isUserAlreadyUnassigned(userItem);

                return (
                  <div
                    className={`flex text-nowrap ${isLastInGroup ? 'border-b-[1px] border-smena_gray-30' : 'border-b-[1px] border-transparent'}`}
                    key={`${userItem.user.id}-${userIndex}`}
                  >
                    <div className="flex gap-2 justify-between w-[200px] pt-2 pb-2 pl-4 pr-4 Body2 border-r-[1px] border-smena_gray-30 text-primary overflow-hidden text-ellipsis">
                      {userIndex === 0 ? (
                        <Link to={`/users/${userItem.user.id}`} className="flex-1 overflow-hidden text-ellipsis">
                          {userItem.user.firstname && userItem.user.lastname
                            ? getUserFIO({
                                firstname: userItem.user.firstname,
                                lastname: userItem.user.lastname,
                                patronymic: userItem.user.patronymic,
                              })
                            : convertPhoneNumberToNormal(userItem.user.phone)}
                        </Link>
                      ) : (
                        <span>&nbsp;</span>
                      )}

                      {userIndex === 0 && (
                        <div className="flex gap-2">
                          {!isMedBookValid(userItem.user.requisite) && (
                            <Tooltip title="Отсутствует мед. книжка">
                              <span>
                                <MedicalCase />
                              </span>
                            </Tooltip>
                          )}
                          {(userItem.user.registrationState?.registrationInNaimixState !== 'FULL_REGISTRATION' ||
                            !userItem.user.requisite?.cardNumber ||
                            userItem.user.registrationState.taxPartnerInfo !== 'CONFIRMED' ||
                            userItem.user.contracts?.some((contract: any) => contract.status === 'Unsigned')) && (
                            <Tooltip
                              title={getDocumentIconTitle({
                                registrationState: userItem.user.registrationState?.registrationInNaimixState,
                                taxPatnerInfo: userItem.user.registrationState?.taxPartnerInfo,
                                cardNumber: userItem.user.requisite?.cardNumber,
                                contracts: userItem.user.contracts,
                              })}
                            >
                              <span>
                                <Document />
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>

                    <div
                      className={`w-[150px] pt-2 pb-2 pl-4 pr-4 Body2 border-r-[1px] border-smena_gray-30 overflow-hidden text-ellipsis`}
                    >
                      <Tooltip title={userItem.workPost?.position?.name}>
                        {userIndex === 0 ? userItem.workPost?.position?.name || 'Без профессии' : <span>&nbsp;</span>}
                      </Tooltip>
                    </div>

                    <div className="flex gap-4 pt-2 pb-2 pl-4 pr-4 Body2 w-[110px]">
                      {userIndex === 0 && (
                        <>
                          <Tooltip title="Предложить график">
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                if (!isCalendar) {
                                  setCreateScheduleMenu(true);
                                  setUser({ ...userItem.user, positionId: userItem.workPost?.position?.id });
                                  setSelectedRow(index);
                                  setSelectedDay(null);
                                }
                              }}
                            >
                              <CalendarContained />
                            </span>
                          </Tooltip>
                          <Tooltip title={isUnassigned ? 'Пользователь уже откреплен' : 'Открепить пользователя'}>
                            <span
                              className={clsx('cursor-pointer', {
                                'opacity-50 pointer-events-none': isUnassigned,
                              })}
                              onClick={() => {
                                if (!isUnassigned) {
                                  setShowStopCooperationSidebar(true);
                                  setUser({ ...userItem.user, positionId: userItem.workPost?.position?.id });
                                }
                              }}
                            >
                              <UnassignWorker />
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ))}

        <div className="uppercase Table-H1 pt-2 pb-2 pl-4 pr-4 border-b-[1px] border-smena_gray-30 h-[36px] bg-smena_gray-5 font-medium">
          Всего смен
        </div>
      </div>
    </div>
  );
};

export default Performers;
