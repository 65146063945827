import { ShiftStage } from 'generated/graphql';
import { ShiftFactFragment } from 'pages/moderationReport/api/fragments/generated/ShiftFact.fragment';

export const getShiftBgColorPast = (shift: ShiftFactFragment) => {
  const { stage, moderation } = shift;

  if (stage === ShiftStage.Wasted || stage === ShiftStage.New) {
    if (moderation?.toPayment === true) {
      return 'bg-smena_green-light';
    } else if (moderation?.toPayment === false) {
      return 'bg-smena_red-light';
    }

    return 'bg-smena_yellow-light';
  }

  if (stage === ShiftStage.Finished) {
    if (moderation?.toPayment) {
      return 'bg-smena_green-light';
    }

    return 'bg-smena_red-light';
  }

  // Не редактируем
  if (stage === ShiftStage.Refused) {
    return 'bg-smena_red-light';
  }

  if (stage === ShiftStage.Ineligible) {
    return 'bg-smena_gray-40';
  }

  return 'bg-smena_yellow-light';
};
