import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import MainSidebar from 'ui/sidebar';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import { useSearchParams } from 'react-router-dom';
import ShiftTitle from './components/shiftTitle';
import Hold from './components/Hold';
import Surcharge from './components/Surcharge';
import ShiftPlan from './components/shiftPlan';
import ShiftFact from './components/shiftFact';
import ShiftBonus from './components/shiftBonus';
import UpdateShiftPayment from './components/UpdateShiftPayment';
import { useFacility } from 'utils/api/useFacility';
import ShiftComments from './components/shiftComments';
import { useStore } from 'store';
import Plus from 'assets/icons/plus';
import { ShiftStage } from 'generated/graphql';

const EditModerationShift = () => {
  const { facility } = useFacility();
  const { setShowSidebar, shift } = useStore();

  const { setShowEditModerationShiftSidebar, showEditModerationShiftSidebar, selectedDay, user } =
    useFacilityReportStore();

  const [params] = useSearchParams();
  const dateParam = params.get('date');

  if (!selectedDay || !dateParam) {
    return null;
  }

  const modifiedDate = dayjs(dateParam).date(selectedDay);

  const title = modifiedDate.format(RU_DATE);

  return (
    <MainSidebar
      title={title}
      show={showEditModerationShiftSidebar}
      setShow={setShowEditModerationShiftSidebar}
      className={'!w-[450px] !px-0'}
      titleClassName={'px-5'}
    >
     
      <div className="mb-5 flex flex-col gap-x-1 px-5">
        <div className="flex gap-x-1 H4">
         {user?.user?.lastname && <span>{user?.user?.lastname}</span>} 
         {user?.user?.firstname && <span>{user?.user?.firstname}</span>}
        </div>

        <div className="flex gap-x-1 Subtitle2">
          <span>{facility?.facilityGroup?.name} ·</span>
          <span>{facility?.name}</span>
          <span className="truncate">· {user?.workPost?.position?.name}</span>
        </div>
      </div>

      <ShiftTitle />
      <div className='px-5'>
      <div className="gap-y-3">
        {shift?.stage === ShiftStage.Refused ? null : (
          <>
            <div className="shadow-smena rounded-lg mb-4">
            <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>
              Информация о смене
            </p>
              <ShiftPlan />
              <ShiftFact />
            </div>

            <ShiftComments />
            <UpdateShiftPayment />
            <ShiftBonus />
          </>
        )}
      </div>

      <Hold />
      <Surcharge />
      </div>

      <button
        type="button"
        className="p-4 w-full text-left text-primary Button1 flex items-center justify-between border-t"
        onClick={() => {
          setShowEditModerationShiftSidebar(false);
          setShowSidebar(true);
        }}
      >
        Добавить новую смену
        <Plus className={'text-primary'} />
      </button>
    </MainSidebar>
  );
};

export default EditModerationShift;
