import React, { useRef, useState, useMemo } from 'react';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../users/lib/useParams';
import Search from 'ui/search';
import { Table } from '@tanstack/react-table';
import ColumnSettings from 'components/table/columnSettings';
import { LockedPeriodFragment } from 'pages/facilityReport/api/fragments/generated/LockedPeriod.fragment';
import SimpleSelect from 'ui/select';
import Filter from 'components/table/filters/filter';

interface Props {
  table: Table<LockedPeriodFragment>;
  positions?: Array<{
    id: string;
    name: string;
  }>;
}

const FacilityPeriodFilter = ({ table, positions }: Props) => {
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();

  const professionParam = params.get('profession');

  const [profession, setProfession] = useState<string | null>(professionParam);
  const [showFilter, setShowFilter] = useState(false);

  const btnRef = useRef<HTMLButtonElement>(null);
  const paramsCount = useParamsCount();

  const professionOptions = useMemo(() => {
    if (!positions) return [];

    return positions.map(position => ({
      id: position.id,
      name: position.name,
    }));
  }, [positions]);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <Search />
          <button
            type="button"
            className="btn-stroke flex gap-x-1 items-center"
            ref={btnRef}
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<LockedPeriodFragment> table={table} title='' />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              divClassName=""
              label="Профессия"
              onChange={value => setProfession(value)}
              value={profession}
              allOption={'Все профессии'}
              placeholder={'Не выбрано'}
              options={professionOptions}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('profession', profession);
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke"
                onClick={() => {
                  setProfession(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                <span>Сбросить</span>
              </button>
            </div>
          </TableFilter>
        </div>
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!professionParam}
              content={'Профессия: ' + professionOptions.find(p => p.id === professionParam)?.name || professionParam}
              clickHandler={() => {
                setProfession(null);
                resetFilter('profession');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FacilityPeriodFilter;
