import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useMemo, useState, useCallback } from 'react';
import { LockedPeriodFragment } from 'pages/facilityReport/api/fragments/generated/LockedPeriod.fragment';
import dayjs from 'dayjs';
import { SortingWay } from 'generated/graphql';
import clsx from 'clsx';
import { useGetCurrentSelfEmployedTaxQuery } from 'pages/facilityGroupCasing/api/query/generated/GetCurrentSelfEmployedTax';
import { useSearchParams } from 'react-router-dom';
import Up from 'assets/icons/sort/up';
import Down from 'assets/icons/sort/down';
import { getPeriodStatusBadge } from 'utils/helpers/constVariables/periodStatuses';

const SortableHeader = ({
  title,
  columnId,
  sortBy,
  sortWay,
  onSort
}: {
  title: string;
  columnId: string;
  sortBy: string | null;
  sortWay: SortingWay | null;
  onSort: (columnId: string) => void
}) => {
  const isActive = sortBy === columnId;
  const isDesc = isActive && sortWay === SortingWay.Desc;
  const isAsc = isActive && sortWay === SortingWay.Asc;

  return (
    <div
      className="flex items-center gap-x-2 cursor-pointer"
      onClick={() => onSort(columnId)}
    >
      <span>{title}</span>
      <span className="flex flex-col gap-y-1">
        <Up className={isAsc ? 'text-smena_text-helper' : 'text-smena_text-disabled'} />
        <Down className={isDesc ? 'text-smena_text-helper' : 'text-smena_text-disabled'} />
      </span>
    </div>
  );
};

export const useFacilityPeriodsTable = (data: LockedPeriodFragment[]) => {
  const { data: currentSelfTax } = useGetCurrentSelfEmployedTaxQuery();
  const [params, setParams] = useSearchParams();
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay') as SortingWay;

  const handleSorting = useCallback((columnId: string) => {
    if (sortBy === columnId) {
      if (sortWay === SortingWay.Desc) {
        params.set('sortWay', SortingWay.Asc);
        setParams(params);
      } else if (sortWay === SortingWay.Asc) {
        params.delete('sortBy');
        params.delete('sortWay');
        setParams(params);
      } else {
        params.set('sortWay', SortingWay.Desc);
        setParams(params);
      }
    } else {
      params.set('sortBy', columnId);
      params.set('sortWay', SortingWay.Desc);
      setParams(params);
    }
  }, [sortBy, sortWay, params, setParams]);

  const columns: ColumnDef<LockedPeriodFragment>[] = useMemo(() => {
    return [
      {
        id: 'erpId',
        headerName: 'Номер',
        header: () => (
          <SortableHeader
            title="Номер"
            columnId="erpId"
            sortBy={sortBy}
            sortWay={sortWay}
            onSort={handleSorting}
          />
        ),
        size: 120,
        minSize: 120,
        accessorKey: 'erpId',
        cell: ({ row: { original } }) => {
          return (
            <Link to={`${original.id}`} className='link'>
              {original.erpId === '-' && 'Не указан'}
            </Link>
          );
        },
      },
      {
        id: 'period',
        header: 'Период выплаты',
        size: 200,
        minSize: 200,
        cell: ({ row: { original } }) => {
          return original.facility ? (
            <Link
              to={`/facilities/${original.facility.id}/tabel/?date=${dayjs(original.startDate).format('YYYY-MM-DD')}`}
              className='link'
            >
              {dayjs(original.startDate).format('DD.MM.YYYY')} - {dayjs(original.endDate).format('DD.MM.YYYY')}
            </Link>
          ) : '-';
        },
      },
      {
        id: 'dateOfCreation',
        headerName: 'Дата создания',
        header: () => (
          <SortableHeader
            title="Дата создания"
            columnId="dateOfCreation"
            sortBy={sortBy}
            sortWay={sortWay}
            onSort={handleSorting}
          />
        ),
        size: 140,
        minSize: 140,
        accessorKey: 'createdAt',
        cell: ({ row: { original } }) => {
          return dayjs(original.createdAt).format('DD.MM.YYYY');
        },
      },
      {
        id: 'author',
        header: 'Автор',
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return original.facility ? (
            <Link to={`/users/${original.author?.id}`} className='link'>
              {original.author ? `${original.author.firstname} ${original.author.lastname}` : '-'}
            </Link>
          ) : '-';
        },
      },
      {
        id: 'totalAmount',
        headerName: 'Сумма, ₽',
        header: () => (
          <div className="w-full ml-auto text-right h-full">
            Сумма, ₽
          </div>
        ),
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return (
            <div className="text-right w-full">
              {Number(original.totalAmount).toFixed(2)} ₽
            </div>
          );
        },
      },
      {
        id: 'totalAmountWithNpd',
        headerName: 'Сумма с НПД, ₽',
        header: () => (
          <div className="w-full ml-auto text-right h-full" style={{ maxWidth: '65px' }}>
            Сумма с НПД, ₽
          </div>
        ),
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          const amount = original.totalAmount
            ? Math.round((original.totalAmount / Number(currentSelfTax?.getCurrentSelfEmployedTax)) * 100) / 100
            : 0;
          return (
            <div className="text-right w-full">
              {Number(amount).toFixed(2)} ₽
            </div>
          );
        },
      },
      {
        id: 'status',
        headerName: 'Статус',
        header: () => (
          <SortableHeader
            title="Статус"
            columnId="status"
            sortBy={sortBy}
            sortWay={sortWay}
            onSort={handleSorting}
          />
        ),
        size: 130,
        minSize: 130,
        accessorKey: 'status',
        cell: ({ row: { original } }) => {
          const statusBadge = getPeriodStatusBadge(original.status);
          return (
            <span className={clsx(
              'px-2 py-1 rounded-full text-xs',
              statusBadge.className
            )}>
              {statusBadge.text}
            </span>
          );
        },
        meta: {
          sortable: true
        }
      },
      {
        id: 'countPayments',
        header: 'Выплат исполнено',
        size: 130,
        minSize: 130,
        cell: ({ row: { original } }) => {
          return `0/${original.countPayments}`;
        },
      },
      {
        id: 'dateOfExecution',
        headerName: 'Дата исполнения',
        header: () => (
          <SortableHeader
            title="Дата исполнения"
            columnId="dateOfExecution"
            sortBy={sortBy}
            sortWay={sortWay}
            onSort={handleSorting}
          />
        ),
        size: 200,
        minSize: 200,
        accessorKey: 'dateOfExecution',
        cell: ({ row: { original } }) => {
          return original.dateOfExecution ? dayjs(original.dateOfExecution).format('DD.MM.YYYY') : '-';
        },
      },
    ];
  }, [
    currentSelfTax?.getCurrentSelfEmployedTax,
    sortBy,
    sortWay,
    handleSorting
  ]);

  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string)
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
      sorting: sortBy ? [{ id: sortBy, desc: sortWay === SortingWay.Desc }] : [],
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
