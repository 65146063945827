export const statisticsColumns = [
  {
    name: 'shifts',
    className:
      'w-[90px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 90, minWidth: 90 },
    Header: 'Смены',
    sorted: false,
  },
  {
    name: 'hours',
    className:
      'w-[80px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 90, minWidth: 90 },
    Header: 'Часы',
    sorted: false,
  },
  {
    name: 'shiftsForPayment',
    className:
      'w-[155px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 145, minWidth: 145 },
    Header: 'Смен к оплате',
    sorted: false,
  },
  {
    name: 'fact',
    className:
      'w-[80px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 100, minWidth: 100 },
    Header: 'Факт',
    sorted: false,
  },
  {
    name: 'plan',
    className:
      'w-[80px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 100, minWidth: 100 },
    Header: 'План',
    sorted: false,
  },
  {
    name: 'shiftsPayment',
    className:
      'w-[90px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 120, minWidth: 120 },
    Header: 'По ставке, ₽',
    sorted: false,
  },
  {
    name: 'bonusPayment',
    className:
      'w-[90px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 90, minWidth: 90 },
    Header: 'Бонус, ₽',
    sorted: false,
  },
  {
    name: 'arrestedPayment',
    className:
      'w-[90px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 110, minWidth: 110 },
    Header: 'Удерж., ₽',
    sorted: false,
  },
  {
    name: 'salaryPayment',
    className:
      'w-[90px] h-[36px] flex justify-center items-center px-2 border-r-[1px] border-b-[1px] border-smena_gray-30',
    style: { width: 110, minWidth: 110 },
    Header: 'Надбав., ₽',
    sorted: false,
  },
  {
    name: 'sum',
    className:
      'w-[90px] h-[36px] flex justify-center items-center px-2 border-b-[1px] border-smena_gray-30',
    style: { width: 90, minWidth: 90 },
    Header: 'Сумма, ₽',
    sorted: false,
  },
];
