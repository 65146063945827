import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateArrestedPaymentMutationVariables = Types.Exact<{
  input: Types.CreateArrestedPaymentInput;
}>;


export type CreateArrestedPaymentMutation = { __typename?: 'Mutation', createArrestedPayment: { __typename?: 'ModerationArrestedPaymentEntity', id: string, createdAt: any, updatedAt?: any | null } };


export const CreateArrestedPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateArrestedPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateArrestedPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createArrestedPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode;
export type CreateArrestedPaymentMutationFn = Apollo.MutationFunction<CreateArrestedPaymentMutation, CreateArrestedPaymentMutationVariables>;

/**
 * __useCreateArrestedPaymentMutation__
 *
 * To run a mutation, you first call `useCreateArrestedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArrestedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArrestedPaymentMutation, { data, loading, error }] = useCreateArrestedPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArrestedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateArrestedPaymentMutation, CreateArrestedPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArrestedPaymentMutation, CreateArrestedPaymentMutationVariables>(CreateArrestedPaymentDocument, options);
      }
export type CreateArrestedPaymentMutationHookResult = ReturnType<typeof useCreateArrestedPaymentMutation>;
export type CreateArrestedPaymentMutationResult = Apollo.MutationResult<CreateArrestedPaymentMutation>;
export type CreateArrestedPaymentMutationOptions = Apollo.BaseMutationOptions<CreateArrestedPaymentMutation, CreateArrestedPaymentMutationVariables>;