import Plus from 'assets/icons/plus';
import Minus from 'assets/icons/iconMinus';
import Delete from 'assets/icons/Across';
import { useState } from 'react';
import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import TextArea from 'ui/textArea';
import { tabelSurchargeReasons } from 'utils/helpers/lists/reasons';

interface Props {
  values: any;
  setFieldValue: any;
  handleChange: any;
  errors: any;
  touched: any;
}

const MAX_SURCHARGES = 5;

const Surcharge = (props: Props) => {
  const { values, setFieldValue, handleChange, errors, touched } = props;

  const [show, setShow] = useState(false);

  const canAddMore = values.salaryPayments.length < MAX_SURCHARGES;

  const addNewSurcharge = () => {
    if (canAddMore) {
      setFieldValue('salaryPayments', [
        ...values.salaryPayments,
        { sum: '', cause: '', comment: '' }
      ]);
    }
  };

  const removeSurcharge = (index: number) => {
    const newPayments = values.salaryPayments.filter((_: any, i: number) => i !== index);
    setFieldValue('salaryPayments', newPayments);
  };

  return (
    <div className="gap-y-3 border-b border-smena_gray-30 shadow-smena rounded-lg mb-4">
      <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>Надбавки</p>
      <button
        className={'Button2 text-primary flex items-center gap-x-[10px] px-4 py-2'}
        type="button"
        onClick={() => setShow(prevState => !prevState)}
      >
        {show ? <Minus className={'text-primary'} /> : <Plus className={'text-primary'} />}
        <span className="Button1">Добавить надбавку</span>
      </button>

      {show && (
        <div className="px-4">
          {values.salaryPayments.map((payment: any, index: number) => (
            <div key={index}>
              <div className="mb-4">
                <div className="flex justify-between items-start mb-2">
                  <span />
                  <button
                    type="button"
                    onClick={() => removeSurcharge(index)}
                    className="text-smena_text-secondary hover:text-smena_text-primary"
                  >
                    <Delete className="w-5 h-5" />
                  </button>
                </div>
                <div className={'grid grid-cols-2'}>
                  <div className="col-span-1 flex flex-col gap-y-2">
                    <span className="Subtitle2">Сумма</span>
                    <span className="Table-small text-smena_text-secondary">{`${Number(payment.sum) || 0} ₽`}</span>
                  </div>
                  <SimpleInput
                    divClassName="col-span-1"
                    label="Ред. сумму"
                    onChange={handleChange}
                    regText
                    value={payment.sum}
                    type="text"
                    name={`salaryPayments.${index}.sum`}
                    error={touched[`salaryPayments.${index}.sum`] && errors[`salaryPayments.${index}.sum`] ? errors[`salaryPayments.${index}.sum`] : ''}
                  />
                </div>

                <div className="flex flex-col gap-y-3 pb-2">
                  <SimpleSelect
                    divClassName="relative"
                    label="Причина надбавки"
                    onChange={value => {
                      setFieldValue(`salaryPayments.${index}.cause`, value);
                    }}
                    value={payment.cause}
                    placeholder="Все причины"
                    options={tabelSurchargeReasons}
                    popupClassName={'fixed'}
                    error={touched[`salaryPayments.${index}.cause`] && errors[`salaryPayments.${index}.cause`] ? errors[`salaryPayments.${index}.cause`] : ''}
                  />
                  <TextArea
                    style={{
                      height: '100px',
                    }}
                    placeholder={'Комментарий'}
                    label={'Оставьте комментарий'}
                    onChange={handleChange}
                    value={payment.comment}
                    name={`salaryPayments.${index}.comment`}
                    error={touched[`salaryPayments.${index}.comment`] && errors[`salaryPayments.${index}.comment`] ? errors[`salaryPayments.${index}.comment`] : ''}
                  />
                </div>
              </div>
              {index < values.salaryPayments.length - 1 && (
                <div className="border-b border-smena_gray-30 mb-4" />
              )}
            </div>
          ))}

          {canAddMore && (
            <button
              type="button"
              onClick={addNewSurcharge}
              className="Button2 text-primary flex items-center gap-x-[10px] mb-4"
            >
              <Plus className={'text-primary'} />
              <span>Добавить еще надбавку</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Surcharge;
