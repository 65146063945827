import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReadAllCommentsInShiftMutationVariables = Types.Exact<{
  input: Types.ReadAllCommentsInShiftInput;
}>;


export type ReadAllCommentsInShiftMutation = { __typename?: 'Mutation', readAllCommentsInShift: number };


export const ReadAllCommentsInShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReadAllCommentsInShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReadAllCommentsInShiftInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"readAllCommentsInShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type ReadAllCommentsInShiftMutationFn = Apollo.MutationFunction<ReadAllCommentsInShiftMutation, ReadAllCommentsInShiftMutationVariables>;

/**
 * __useReadAllCommentsInShiftMutation__
 *
 * To run a mutation, you first call `useReadAllCommentsInShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllCommentsInShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllCommentsInShiftMutation, { data, loading, error }] = useReadAllCommentsInShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadAllCommentsInShiftMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllCommentsInShiftMutation, ReadAllCommentsInShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllCommentsInShiftMutation, ReadAllCommentsInShiftMutationVariables>(ReadAllCommentsInShiftDocument, options);
      }
export type ReadAllCommentsInShiftMutationHookResult = ReturnType<typeof useReadAllCommentsInShiftMutation>;
export type ReadAllCommentsInShiftMutationResult = Apollo.MutationResult<ReadAllCommentsInShiftMutation>;
export type ReadAllCommentsInShiftMutationOptions = Apollo.BaseMutationOptions<ReadAllCommentsInShiftMutation, ReadAllCommentsInShiftMutationVariables>;