import React from 'react';
import DragIcon from 'assets/icons/DragIcon';
import EyeOpen from 'assets/icons/EyeOpen';
import EyeClose from 'assets/icons/EyeClose';
import { UsersFragment } from 'pages/users/api/fragments/generated/Users.fragment';
import { Column, Table } from '@tanstack/react-table';
import { useDrag } from 'react-dnd/dist/hooks/useDrag/useDrag';
import { useDrop } from 'react-dnd/dist/hooks/useDrop/useDrop';
import { reorderColumn } from '../utils/helpers/reorderColumn';

interface Props<T> {
  column: Column<T, unknown>;
  table: Table<T>;
}

const Row = <T,>({ column, table }: Props<T>) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<UsersFragment>) => {
      const newColumnOrder = reorderColumn(draggedColumn.id!, column.id, columnOrder);
      setColumnOrder(newColumnOrder);
    },
  });

  const [, dragRef, dragPreview] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });
  return (
    <div className="first:border-y border-b border-smena_gray-30" ref={dropRef}>
      <div ref={dragPreview} className="flex items-center gap-x-1 py-2">
        <button ref={dragRef}>
          <DragIcon />
        </button>
        <div className={'flex justify-between items-center w-full'}>
          <span>
            <>{typeof column.columnDef.header === 'function' 
              ? (column.columnDef as any).headerName || column.id
              : column.columnDef.header
            }</>
          </span>
          <button onClick={column.getToggleVisibilityHandler()}>
            {column.getIsVisible() ? (
              <EyeOpen className={'fill-current text-smena_text-secondary'} />
            ) : (
              <EyeClose className={'fill-current text-primary'} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Row;
