import clsx from 'clsx';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { getShiftBgColorFuture } from 'pages/facilityReport/lib/helpers/getShiftBgColorFuture';
import { getShiftBgColorPast } from 'pages/facilityReport/lib/helpers/getShiftBgColorPast';
import { useStore } from 'store';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { isFutureOrToday } from 'pages/facilityReport/lib/helpers/isFutureOrToday';
import BMark from 'assets/icons/BMark';

interface Props {
  shift: any;
  supervisor: any;
  dayIndex: number;
  rowIndex: number;
  groupIndex: number;
  isDisabled?: boolean;
  className?: string;
}

const ShiftCell: React.FC<Props> = ({ shift, supervisor, dayIndex, rowIndex, groupIndex, isDisabled, className }) => {
  const { setShift, setShowEditShiftSidebar, setShowCreateRequestShiftSidebar, setShowSidebar } = useStore();
  const {
    setUser,
    setSelectedDay,
    setSelectedRow,
    setSelectedGroup,
    selectedDay,
    selectedRow,
    selectedGroup,
    isCalendar,
    setShowEditModerationShiftSidebar,
    selectedTimezone
  } = useFacilityReportStore();

  const [params] = useSearchParams();
  const dateParam = params.get('date');

  // Используем правильную таймзону при работе с датами
  const getCurrentDay = () => {
    if (selectedTimezone) {
      return dayjs().tz(selectedTimezone).date();
    }
    return dayjs().date();
  };

  const getMonth = (date: string | null) => {
    if (!date) return dayjs().month();

    if (selectedTimezone) {
      return dayjs(date).tz(selectedTimezone).month();
    }
    return dayjs(date).month();
  };

  const getYear = (date: string | null) => {
    if (!date) return dayjs().year();

    if (selectedTimezone) {
      return dayjs(date).tz(selectedTimezone).year();
    }
    return dayjs(date).year();
  };

  const currentDay = getCurrentDay();

  let cellClass = 'min-w-[70px] h-[36px] border-r-[1px] font-medium cursor-pointer';

  if (shift) {
    const bg = isFutureOrToday(dayIndex, dateParam) ? getShiftBgColorFuture(shift) : getShiftBgColorPast(shift);
    cellClass = clsx(cellClass, bg);
  }

  const isSelected = selectedDay === dayIndex && selectedRow === rowIndex && groupIndex === selectedGroup;

  return (
    <div
      className={clsx(cellClass, {
        ['border-l-[2px] border-r-[2px] border-primary']:
          currentDay === dayIndex &&
          getMonth(null) === getMonth(dateParam) &&
          getYear(null) === getYear(dateParam),
        ['border-[1px] border-primary']: !isCalendar && isSelected,
        ['border-b-[2px] border-b-smena_purple']: shift?.isProbation,
        ['cursor-not-allowed opacity-50']: isDisabled,
      }, className)}
      onClick={() => {
        if (!isCalendar && !isDisabled) {
          if (shift) {
            setShift(shift);
            setUser({ user: supervisor.user, workPost: supervisor.workPost });
            if (isFutureOrToday(dayIndex, dateParam)) {
              setShowEditShiftSidebar(true);
            } else {
              setShowEditModerationShiftSidebar(true);
            }
          } else {
            setUser({ user: supervisor.user, workPost: supervisor.workPost });
            if (isFutureOrToday(dayIndex, dateParam)) {
              setShowCreateRequestShiftSidebar(true);
            } else {
              setShowSidebar(true);
            }
          }

          setSelectedDay(dayIndex);
          setSelectedRow(rowIndex);
          setSelectedGroup(groupIndex);
        }
      }}
    >
      <div className="relative w-full h-full flex justify-center items-center overflow-hidden">
        {shift?.bonusForThisShift ? (
          <span className="absolute top-[2px] left-[2px]">
            <BMark />
          </span>
        ) : null}
        {shift
          ? shift.durationFact && shift.durationFact > shift.lunchDuration
            ? (() => {
              const totalMinutes = shift.durationFact;
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
              return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            })()
            : shift.duration > shift.lunchDuration
              ? (() => {
                const totalMinutes = shift.duration;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;
                return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
              })()
              : '00:00'
          : null}
        {shift?.comments?.length && shift?.newCommentsNum > 0 ? (
          <span className="employer__mark-unread top-0 right-[-3px]" />
        ) : null}
        {shift?.comments?.length && shift?.newCommentsNum <= 0 ? (
          <span className="employer__mark top-0 right-[-3px]" />
        ) : null}
      </div>
    </div>
  );
};

export default ShiftCell;
