import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useStore } from 'store';

const PaymentCheckbox = ({ userId }: { userId?: string }) => {
  const {
    manualAccrual,
    paymentCheckbox,
    manualAccrualUserIds,
    setManualAccrualUserIds,
    paymentCheckboxes,
    setPaymentCheckboxes,
  } = useStore();
  const [checked, setChecked] = useState(true);
  const disabledAccrual = manualAccrual?.manualUserAccruals?.find((accrual: any) => accrual.userId === userId);

  useEffect(() => {
    if (disabledAccrual) {
      const remove = manualAccrualUserIds.filter(id => id !== disabledAccrual.userId);
      setManualAccrualUserIds([...remove]);
    }
  }, [disabledAccrual, manualAccrualUserIds, setManualAccrualUserIds]);

  useEffect(() => {
    setChecked(paymentCheckbox);
  }, [paymentCheckbox]);

  return (
    <input
      type="checkbox"
      name="paymentCheckbox"
      disabled={!!disabledAccrual}
      className={clsx('mx-4 my-2 checkbox-primary', disabledAccrual && 'cursor-not-allowed bg-smena_gray-80')}
      checked={disabledAccrual ? true : checked}
      onChange={({ target: { checked } }) => {
        setChecked(checked);
        if (checked) {
          setPaymentCheckboxes(paymentCheckboxes + 1);
          if (userId) {
            setManualAccrualUserIds([...manualAccrualUserIds, userId]);
          }
        } else {
          const remove = manualAccrualUserIds.filter(id => id !== userId);
          setManualAccrualUserIds([...remove]);
          setPaymentCheckboxes(paymentCheckboxes - 1);
        }
      }}
    />
  );
};

export default PaymentCheckbox;
