import React, { useRef, useState, useMemo } from 'react';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../users/lib/useParams';
import Search from 'ui/search';
import { Table } from '@tanstack/react-table';
import ColumnSettings from 'components/table/columnSettings';
import { LockedPeriodFragment } from 'pages/facilityReport/api/fragments/generated/LockedPeriod.fragment';
import SimpleSelect from 'ui/select';
import { WorkSheetLockedPeriodStatusEnum } from 'generated/graphql';
import Filter from 'components/table/filters/filter';
import dayjs, { Dayjs } from 'dayjs';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useFacilityById } from 'utils/api/useFacility';
import { useFacilityGroup } from 'utils/api/useFacilityGroup';

interface Props {
  table: Table<LockedPeriodFragment>;
}

const statusOptions = [
  { id: WorkSheetLockedPeriodStatusEnum.Rejected, name: 'Отклонён' },
  { id: WorkSheetLockedPeriodStatusEnum.InProgress, name: 'В процессе' },
  { id: WorkSheetLockedPeriodStatusEnum.Sent, name: 'Отправлен' },
  { id: WorkSheetLockedPeriodStatusEnum.Approved, name: 'Утверждён' },
  { id: WorkSheetLockedPeriodStatusEnum.Executed, name: 'Исполнен' },
  { id: WorkSheetLockedPeriodStatusEnum.Cancelled, name: 'Отменён' },
];

const statusLabels = {
  [WorkSheetLockedPeriodStatusEnum.Rejected]: 'Отклонён',
  [WorkSheetLockedPeriodStatusEnum.InProgress]: 'В процессе',
  [WorkSheetLockedPeriodStatusEnum.Sent]: 'Отправлен',
  [WorkSheetLockedPeriodStatusEnum.Approved]: 'Утверждён',
  [WorkSheetLockedPeriodStatusEnum.Executed]: 'Исполнен',
  [WorkSheetLockedPeriodStatusEnum.Cancelled]: 'Отменён',
};

const FacilityGroupPeriodsFilter = ({ table }: Props) => {
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();
  const { id: facilityGroupId } = useFacilityGroup();

  const facilityParam = params.get('facilityId');
  const statusParam = params.get('status');
  const createdAtParam = params.get('createdAt');
  const executionDateParam = params.get('executionDate');

  const [facility, setFacility] = useState(facilityParam);
  const [status, setStatus] = useState<WorkSheetLockedPeriodStatusEnum | null>(
    statusParam ? (statusParam as WorkSheetLockedPeriodStatusEnum) : null
  );
  const [createdAt, setCreatedAt] = useState<Dayjs | null>(createdAtParam ? dayjs(createdAtParam) : null);
  const [executionDate, setExecutionDate] = useState<Dayjs | null>(executionDateParam ? dayjs(executionDateParam) : null);
  const [showFilter, setShowFilter] = useState(false);

  const btnRef = useRef<HTMLButtonElement>(null);
  const paramsCount = useParamsCount();

  const { facilities } = useSelectFacilities();
  const { facility: facilityData, loading: facilityLoading } = useFacilityById(facilityParam);

  const filteredFacilities = useMemo(() => {
    if (!facilityGroupId) return facilities;
    return facilities.filter(facility => facility.facilityGroupId === facilityGroupId);
  }, [facilities, facilityGroupId]);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <Search />
          <button
            type="button"
            className="btn-stroke flex gap-x-1 items-center"
            ref={btnRef}
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<LockedPeriodFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              divClassName=""
              label="Объект"
              onChange={value => setFacility(value)}
              value={facility}
              allOption={'Все объекты'}
              placeholder={'Не выбрано'}
              options={filteredFacilities}
            />

            <SimpleSelect
              divClassName=""
              label="Статус"
              onChange={value => setStatus(value as WorkSheetLockedPeriodStatusEnum)}
              value={status}
              allOption={'Все статусы'}
              placeholder={'Не выбрано'}
              options={statusOptions}
            />

            <DatePickerInput
              label="Дата создания"
              value={createdAt}
              onChange={value => setCreatedAt(value)}
              placeholder="Выберите дату"
            />

            <DatePickerInput
              label="Дата исполнения"
              value={executionDate}
              onChange={value => setExecutionDate(value)}
              placeholder="Выберите дату"
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('facilityId', facility);
                  handleParams('status', status);
                  handleParams('createdAt', createdAt?.format('YYYY-MM-DD'));
                  handleParams('executionDate', executionDate?.format('YYYY-MM-DD'));
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke"
                onClick={() => {
                  setFacility(null);
                  setStatus(null);
                  setCreatedAt(null);
                  setExecutionDate(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                <span>Сбросить</span>
              </button>
            </div>
          </TableFilter>
        </div>
        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!facilityParam}
              content={facilityLoading ? 'Объект: загрузка...' : facilityData ? 'Объект: ' + facilityData.name : 'Объект: не найден'}
              clickHandler={() => {
                setFacility(null);
                resetFilter('facilityId');
              }}
            />

            <Filter
              show={!!statusParam}
              content={'Статус: ' + statusLabels[statusParam as WorkSheetLockedPeriodStatusEnum]}
              clickHandler={() => {
                setStatus(null);
                resetFilter('status');
              }}
            />

            <Filter
              show={!!createdAtParam}
              content={'Создано: ' + dayjs(createdAtParam).format('DD.MM.YYYY')}
              clickHandler={() => {
                setCreatedAt(null);
                resetFilter('createdAt');
              }}
            />

            <Filter
              show={!!executionDateParam}
              content={'Исполнено: ' + dayjs(executionDateParam).format('DD.MM.YYYY')}
              clickHandler={() => {
                setExecutionDate(null);
                resetFilter('executionDate');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FacilityGroupPeriodsFilter;
