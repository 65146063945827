import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import SkeletonLayout from 'components/table/Skeleton';
import { statisticsColumns } from './constants';
import React from 'react';
import RowStats from './components/RowStats';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
  loading?: boolean;
}

const Statistics: React.FC<Props> = ({ data, loading }) => {
  const groupedData = data?.reduce(
    (acc, item) => {
      const professionId = item.workPost?.position?.id;
      const key = `${professionId}-${item.user.id}`;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Record<string, typeof data>,
  );

  return (
    <div className="border-l-[1px] border-l-smena_gray-60 h-full">
      <div className="flex bg-smena_gray-5 Table-H1 uppercase sticky top-0 z-50">
        {statisticsColumns.map(column => (
          <div key={column.name + column.Header} className={column.className} style={column.style}>
            {column.Header}
          </div>
        ))}
      </div>

      {loading && <SkeletonLayout columns={statisticsColumns} rows={5} />}

      <div>
        {groupedData &&
          Object.entries(groupedData).map(([, users], groupIndex) => (
            <React.Fragment key={groupIndex}>
              {users.map((userItem, userIndex) => {
                const isLastInGroup = userIndex === users.length - 1;
                return (
                  <div
                    key={userIndex}
                    className={`${isLastInGroup ? 'border-b-[1px] border-smena_gray-30' : 'border-b-[1px] border-transparent'}`}
                  >
                    <RowStats
                      row={userItem}
                      isTotal={false}
                      showData={userIndex === 0}
                      totalAnalytics={
                        userIndex === 0
                          ? {
                            shiftsCount: users.reduce((acc, item) => acc + (item.shifts?.length || 0), 0),
                            workTimeFact: users.reduce((acc, item) => acc + (item.analytics?.workTimeFact || 0), 0),
                            salaryPayment: users.reduce((acc, item) => acc + (item.analytics?.salaryPayment || 0), 0),
                            arrestedPayment: users.reduce((acc, item) => acc + (item.analytics?.arrestedPayment || 0), 0),
                            bonusPayment: users.reduce((acc, item) => acc + (item.analytics?.bonusPayment || 0), 0),
                            shiftsPayment: users.reduce((acc, item) => acc + (item.analytics?.shiftsPayment || 0), 0),
                            toPaymentCount: users.reduce(
                              (acc, item) => acc + (item.analytics?.toPaymentCount || 0),
                              0,
                            ),
                            workTimePlan: users.reduce((acc, item) => acc + (item.analytics?.workTimePlan || 0), 0),
                          }
                          : undefined
                      }
                    />
                  </div>
                );
              })}
            </React.Fragment>
          ))}

        <div className="flex border-b-[1px] border-smena_gray-30 h-[36px]">
          {statisticsColumns.map((column, index) => (
            <div
              key={column.name + index}
              className={`h-[36px] ${index !== statisticsColumns.length - 1 ? 'border-r-[1px] border-smena_gray-30' : ''
                }`}
              style={{ width: column.style.width }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
