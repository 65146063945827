import create, { StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';
import { DoNotShowShiftEnum, GetSupervisorWorkSheetOrderByEnum, OrderDirection } from 'generated/graphql';
import dayjs from 'dayjs';
import { StoreSlice } from 'store';
import { DayOrNullArray } from 'interfaces/CustomTypes';

interface CustomShiftCell {
  groupIndex: number;
  dayIndex: number;
  userIndex: number;
}

interface CustomScheduleType {
  isCalendar: boolean;
  setIsCalendar: (isCalendar: boolean) => void;
  customShifts: CustomShiftCell[];
  setCustomShifts: (shift: CustomShiftCell) => void;
  clearCustomShifts: () => void;
}

export const CustomScheduleSlice: StoreSlice<CustomScheduleType> = set => ({
  clearCustomShifts: () =>
    set(() => {
      return { customShifts: [] };
    }),
  isCalendar: false,
  setIsCalendar: isCalendar =>
    set(() => {
      return { isCalendar };
    }),

  customShifts: [],
  setCustomShifts: shift =>
    set(state => {
      const isExists = state.customShifts.some(
        item =>
          item.dayIndex === shift.dayIndex &&
          item.groupIndex === shift.groupIndex &&
          item.userIndex === shift.userIndex,
      );

      return {
        customShifts: isExists
          ? state.customShifts.filter(
            item =>
              !(
                item.dayIndex === shift.dayIndex &&
                item.groupIndex === shift.groupIndex &&
                item.userIndex === shift.userIndex
              ),
          )
          : [...state.customShifts, shift],
      };
    }),
});

interface ShowEditModerationShiftSidebarType {
  showEditModerationShiftSidebar: boolean;
  setShowEditModerationShiftSidebar: (showEditModerationShiftSidebar: boolean) => void;
}

export const ShowEditModerationShiftSidebar: StoreSlice<ShowEditModerationShiftSidebarType> = set => ({
  showEditModerationShiftSidebar: false,
  setShowEditModerationShiftSidebar: showEditModerationShiftSidebar =>
    set(() => {
      return { showEditModerationShiftSidebar };
    }),
});

interface StopCooperationSidebarType {
  selectedDay: number | null;
  setSelectedDay: (day: number | null) => void;
  selectedRow: number | null;
  setSelectedRow: (groupIndex: number) => void;
  selectedGroup: number | null;
  setSelectedGroup: (groupIndex: number) => void;
  user: any;
  setUser: (user: any) => void;
  showStopCooperationSidebar: boolean;
  setShowStopCooperationSidebar: (showStopCooperationSidebar: boolean) => void;
  stopCooperationPopup: boolean;
  setShowStopCooperationPopup: (stopCooperationPopup: boolean) => void;
  showStopCooperationAtAllFacilitiesPopup: boolean;
  setShowStopCooperationAtAllFacilitiesPopup: (showStopCooperationAtAllFacilitiesPopup: boolean) => void;
  stopCooperationAllPositions: boolean;
  setStopCooperationAllPositions: (stopCooperationAllPositions: boolean) => void;
  stopCooperationDateError: string;
  setStopCooperationDateError: (stopCooperationDateError: string) => void;
}

export const StopCooperationSidebarSlice: StoreSlice<StopCooperationSidebarType> = set => ({
  selectedRow: null,
  setSelectedRow: (indexRow: number) =>
    set(() => {
      return { selectedRow: indexRow };
    }),

  selectedDay: null,
  setSelectedDay: (day: number | null) =>
    set(() => {
      return { selectedDay: day };
    }),

  selectedGroup: null,
  setSelectedGroup: (groupIndex: number) =>
    set(() => {
      return { selectedGroup: groupIndex };
    }),

  user: null,
  setUser: (user: any) =>
    set(() => {
      return { user };
    }),

  showStopCooperationSidebar: false,
  setShowStopCooperationSidebar: showStopCooperationSidebar =>
    set(() => {
      return { showStopCooperationSidebar };
    }),

  showStopCooperationAtAllFacilitiesPopup: false,
  setShowStopCooperationAtAllFacilitiesPopup: showStopCooperationAtAllFacilitiesPopup =>
    set(() => {
      return { showStopCooperationAtAllFacilitiesPopup };
    }),

  stopCooperationAllPositions: false,
  setStopCooperationAllPositions: stopCooperationAllPositions =>
    set(() => {
      return { stopCooperationAllPositions };
    }),

  stopCooperationPopup: false,
  setShowStopCooperationPopup: stopCooperationPopup =>
    set(() => {
      return { stopCooperationPopup };
    }),

  stopCooperationDateError: '',
  setStopCooperationDateError: stopCooperationDateError =>
    set(() => {
      return { stopCooperationDateError };
    }),
});

interface StatisticsStateType {
  statisticsVisible: boolean;
  toggleStatisticsVisible: () => void;
}

export const StatisticsStateSlice: StoreSlice<StatisticsStateType> = set => ({
  statisticsVisible: false,
  toggleStatisticsVisible: () =>
    set(state => {
      return {
        statisticsVisible: !state.statisticsVisible,
      };
    }),
});

interface FacilityReportFiltersType {
  date: string;
  doNotShowShifts: DoNotShowShiftEnum[];
  userIds: string[];
  positionIds: string[];
  setField: (field: string, value: any) => void;
  resetFilters: () => void;
  isFilterLoaded: boolean;
  setIsFilterLoaded: (isLoaded: boolean) => void;
}

export const FacilityReportFiltersSlice: StoreSlice<FacilityReportFiltersType> = set => ({
  date: dayjs().startOf('month').locale('ru').toISOString(),
  doNotShowShifts: [],
  userIds: [],
  positionIds: [],
  isFilterLoaded: false,

  setField: (field, value) =>
    set(state => {
      if (field === 'doNotShowShifts') {
        const updatedShifts = new Set(state.doNotShowShifts);

        if (Array.isArray(value)) {
          const filteredArr = value.filter(shift => !state.doNotShowShifts.includes(shift));

          for (const item of filteredArr) {
            if (updatedShifts.has(item)) {
              updatedShifts.delete(item);
            } else {
              updatedShifts.add(item);
            }
          }
        } else {
          if (updatedShifts.has(value)) {
            updatedShifts.delete(value);
          } else {
            updatedShifts.add(value);
          }
        }

        return { doNotShowShifts: Array.from(updatedShifts) };
      } else {
        return { [field]: value };
      }
    }),

  setIsFilterLoaded: isLoaded => {
    set({
      isFilterLoaded: isLoaded,
    });
  },

  resetFilters: () =>
    set(state => {
      return { ...state, doNotShowShifts: [] };
    }),
});

interface SortStateType {
  order: OrderDirection;
  orderBy: GetSupervisorWorkSheetOrderByEnum;
  setOrder: (order: OrderDirection) => void;
  setOrderBy: (orderBy: GetSupervisorWorkSheetOrderByEnum) => void;
}

export const SortStateSlice: StoreSlice<SortStateType> = set => ({
  order: OrderDirection.Asc,
  orderBy: GetSupervisorWorkSheetOrderByEnum.Lastname,
  setOrder: order => set(() => ({ order })),
  setOrderBy: orderBy => set(() => ({ orderBy })),
});

interface TimeStateType {
  isUsingFacilityTime: boolean;
  setIsUsingFacilityTime: (value: boolean, timezone?: string) => void;
  getCurrentTime: (facilityTimezone?: string) => dayjs.Dayjs;
  getDateInTimezone: (date: string | dayjs.Dayjs, facilityTimezone?: string) => dayjs.Dayjs;
  selectedTimezone: string;
}

export const TimeStateSlice: StoreSlice<TimeStateType> = (set, get) => ({
  isUsingFacilityTime: true,
  selectedTimezone: '',
  setIsUsingFacilityTime: (value: boolean, timezone?: string) => {
    console.log('setIsUsingFacilityTime вызван с параметрами:', { value, timezone });
    const actualValue = timezone ? true : value;
    const actualTimezone = timezone || '';
    console.log('Устанавливаем значения:', { actualValue, actualTimezone });
    set(() => ({
      isUsingFacilityTime: actualValue,
      selectedTimezone: actualTimezone,
    }));
  },
  getCurrentTime: (facilityTimezone?: string) => {
    const state = get();
    if (state.isUsingFacilityTime && facilityTimezone) {
      return dayjs().utc().tz(facilityTimezone);
    }
    return dayjs();
  },
  getDateInTimezone: (date: string | dayjs.Dayjs, facilityTimezone?: string) => {
    const state = get();
    const dayjsDate = typeof date === 'string' ? dayjs(date) : date;

    if (state.isUsingFacilityTime && facilityTimezone) {
      return dayjsDate.tz(facilityTimezone);
    }

    if (state.selectedTimezone) {
      return dayjsDate.tz(state.selectedTimezone);
    }

    return dayjsDate;
  }
});

interface PeriodSelectionType {
  selectedPeriod: DayOrNullArray;
  setSelectedPeriod: (period: DayOrNullArray) => void;
  lockedPeriods: Array<{
    id: string;
    startDate: string;
    endDate: string;
  }>;
  setLockedPeriods: (periods: Array<{ id: string; startDate: string; endDate: string }>) => void;
}

export const PeriodSelectionSlice: StoreSlice<PeriodSelectionType> = set => ({
  selectedPeriod: [null, null],
  setSelectedPeriod: (period) => set(() => ({ selectedPeriod: period })),
  lockedPeriods: [],
  setLockedPeriods: (periods) => set(() => ({ lockedPeriods: periods })),
});

type State = StatisticsStateType &
  FacilityReportFiltersType &
  StopCooperationSidebarType &
  CustomScheduleType &
  ShowEditModerationShiftSidebarType &
  SortStateType &
  TimeStateType &
  PeriodSelectionType;

const createRootSlice = (set: StoreApi<any>['setState'], get: StoreApi<any>['getState']) => ({
  ...StatisticsStateSlice(set, get),
  ...FacilityReportFiltersSlice(set, get),
  ...StopCooperationSidebarSlice(set, get),
  ...CustomScheduleSlice(set, get),
  ...ShowEditModerationShiftSidebar(set, get),
  ...SortStateSlice(set, get),
  ...TimeStateSlice(set, get),
  ...PeriodSelectionSlice(set, get),
});

export const useFacilityReportStore = create<State>()(devtools(createRootSlice, { name: 'facilityReport-store' }));
