import React from 'react';
import clsx from 'clsx';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { statisticsColumns } from '../../constants';
import { UnitType } from 'generated/graphql';

interface Props {
  row?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'][0];
  isTotal?: boolean;
  totalAnalytics?: {
    shiftsCount: number;
    workTimeFact: number;
    toPaymentCount: number;
    workTimePlan: number;
    salaryPayment: number;
    arrestedPayment: number;
    bonusPayment: number;
    shiftsPayment: number;
  };
  showData?: boolean;
}

const RowStats: React.FC<Props> = ({ row, isTotal, totalAnalytics, showData = true }) => {
  const analytics = isTotal || totalAnalytics ? totalAnalytics : row?.analytics;
  const shiftsCount = isTotal || totalAnalytics ? totalAnalytics?.shiftsCount : row?.shifts?.length;

  const formatTime = (decimalHours: number) => {
    if (!decimalHours && decimalHours !== 0) return null;

    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ч.`;
  };

  const formatMoney = (value: number) => {
    return Math.round(value * 100) / 100;
  };

  const getCellContent = (columnName: string) => {
    if (!showData) return null;

    const unit = row?.analytics?.unit;

    switch (columnName) {
      case 'shifts':
        return shiftsCount || 0;
      case 'hours':
        return formatTime(analytics?.workTimeFact || 0);
      case 'fact':
        if (isTotal) {
          return formatTime(analytics?.workTimeFact || 0);
        } else if (unit === UnitType.Hour || !unit) {
          return formatTime(analytics?.workTimeFact || 0);
        } else if (unit === UnitType.Piece) {
          return `${row?.analytics?.pieceFact || 0} шт.`;
        } else if (unit === UnitType.Point) {
          return `${row?.analytics?.pointFact || 0}%`;
        }
        return formatTime(analytics?.workTimeFact || 0);
      case 'shiftsForPayment':
        return analytics?.toPaymentCount || 0;
      case 'plan':
        if (isTotal) {
          return formatTime(analytics?.workTimePlan || 0);
        } else if (unit === UnitType.Hour || !unit) {
          return formatTime(analytics?.workTimePlan || 0);
        } else if (unit === UnitType.Piece) {
          return `${row?.analytics?.piecePlan || 0} шт.`;
        } else if (unit === UnitType.Point) {
          return `${row?.analytics?.pointPlan || 0}%`;
        }
        return formatTime(analytics?.workTimePlan || 0);
      case 'salaryPayment':
        return formatMoney(analytics?.salaryPayment || 0);
      case 'arrestedPayment':
        return formatMoney(analytics?.arrestedPayment || 0);
      case 'bonusPayment':
        return formatMoney(analytics?.bonusPayment || 0);
      case 'shiftsPayment':
        return formatMoney(analytics?.shiftsPayment || 0);
      case 'sum': {
        const shiftsPayment = analytics?.shiftsPayment || 0;
        const salaryPayment = analytics?.salaryPayment || 0;
        const arrestedPayment = analytics?.arrestedPayment || 0;
        const bonusPayment = analytics?.bonusPayment || 0;
        return formatMoney(shiftsPayment + salaryPayment + bonusPayment - arrestedPayment);
      }
      default:
        return shiftsCount || 0;
    }
  };

  return (
    <div
      className={clsx('flex Body2', {
        'bg-smena_gray-5 Table': isTotal,
      })}
    >
      {statisticsColumns.map((column, index) => (
        <div
          key={column.name + index}
          className={`h-[36px] flex items-center pt-2 pb-2 pl-4 pr-4 ${index !== statisticsColumns.length - 1 ? 'border-r-[1px] border-smena_gray-30' : ''
            }`}
          style={{ width: column.style.width }}
        >
          {getCellContent(column.name)}
        </div>
      ))}
    </div>
  );
};

export default RowStats;