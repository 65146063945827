import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSupervisorWorkSheetReportQueryVariables = Types.Exact<{
  input: Types.GetSupervisorWorkSheetInput;
}>;


export type GetSupervisorWorkSheetReportQuery = { __typename?: 'Query', getSupervisorWorkSheetReport: { __typename?: 'File', path: string } };


export const GetSupervisorWorkSheetReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSupervisorWorkSheetReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetSupervisorWorkSheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSupervisorWorkSheetReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetSupervisorWorkSheetReportQuery__
 *
 * To run a query within a React component, call `useGetSupervisorWorkSheetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorWorkSheetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorWorkSheetReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupervisorWorkSheetReportQuery(baseOptions: Apollo.QueryHookOptions<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables> & ({ variables: GetSupervisorWorkSheetReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables>(GetSupervisorWorkSheetReportDocument, options);
      }
export function useGetSupervisorWorkSheetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables>(GetSupervisorWorkSheetReportDocument, options);
        }
export function useGetSupervisorWorkSheetReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables>(GetSupervisorWorkSheetReportDocument, options);
        }
export type GetSupervisorWorkSheetReportQueryHookResult = ReturnType<typeof useGetSupervisorWorkSheetReportQuery>;
export type GetSupervisorWorkSheetReportLazyQueryHookResult = ReturnType<typeof useGetSupervisorWorkSheetReportLazyQuery>;
export type GetSupervisorWorkSheetReportSuspenseQueryHookResult = ReturnType<typeof useGetSupervisorWorkSheetReportSuspenseQuery>;
export type GetSupervisorWorkSheetReportQueryResult = Apollo.QueryResult<GetSupervisorWorkSheetReportQuery, GetSupervisorWorkSheetReportQueryVariables>;