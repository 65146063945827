import MainLayout from 'components/layout/main';
import useFacilityGroupMenu from 'utils/hooks/headerMenu/useFacilityGroupMenu';
import NewTable from 'components/table/newTable';
import { useFacilityGroupBreadcrumbs } from 'pages/facilityGroup/hooks/useFacilityGroupBreadcrumbs';
import { useFacilityGroupTitle } from 'pages/facilityGroup/hooks/useFacilityGroupTitle';
import FacilityGroupPeriodsFilter from './components/FacilityGorupPeriodsFilter';
import { useFacilityGroupPeriodsTable } from 'components/table/columns/facilityGroupPeriods';
import { useGetLockedPeriodsQuery } from 'pages/facilityReport/api/queries/generated/GetLockedPeriods';
import { useFacilityGroup } from 'utils/api/useFacilityGroup';
import { LockedPeriodFragment } from 'pages/facilityReport/api/fragments/generated/LockedPeriod.fragment';
import { useSearchParams } from 'react-router-dom';
import CustomPagination from 'ui/pagination';
import dayjs from 'dayjs';
import { WorkSheetLockedPeriodStatusEnum, SortingWay, GetLockedPeriodsOrderByField, OrderDirection } from 'generated/graphql';

const FacilityGroupPeriods: React.FC = () => {
  const { id } = useFacilityGroup();
  const [params] = useSearchParams();

  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const facilityId = params.get('facilityId');
  const status = params.get('status') as WorkSheetLockedPeriodStatusEnum | null;
  const createdAt = params.get('createdAt');
  const executionDate = params.get('executionDate');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');

  const title = useFacilityGroupTitle();
  const headerMenu = useFacilityGroupMenu();
  const breadCrumbs = useFacilityGroupBreadcrumbs();

  const sortableFields: Record<string, GetLockedPeriodsOrderByField> = {
    erpId: GetLockedPeriodsOrderByField.ErpId,
    createdAt: GetLockedPeriodsOrderByField.CreatedAt,
    status: GetLockedPeriodsOrderByField.Status,
    dateOfExecution: GetLockedPeriodsOrderByField.DateOfExecution
  };

  const {
    data: dataLockedPeriods,
    loading: loadingLockedPeriods,
    error
  } = useGetLockedPeriodsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        facilityGroupId: id || '',
        limit: size,
        offset: (page - 1) * size,
        facilityId: facilityId || undefined,
        status: status || undefined,
        createdAt: createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : undefined,
        dateOfExecution: executionDate ? dayjs(executionDate).format('YYYY-MM-DD') : undefined,
        searchQuery: searchQuery || undefined,
        orderBy: sortBy ? [
          {
            fieldName: sortableFields[sortBy] || GetLockedPeriodsOrderByField.CreatedAt,
            orderDirection: (sortWay === SortingWay.Asc ? OrderDirection.Asc : OrderDirection.Desc)
          }
        ] : undefined,
      },
    },
  });

  const periods = dataLockedPeriods?.getLockedPeriods.data || [];
  const total = dataLockedPeriods?.getLockedPeriods.count || 0;
  const pages = Math.ceil(total / size);

  const table = useFacilityGroupPeriodsTable(periods);

  return (
    <MainLayout title={title} breadCrumbs={breadCrumbs} headerMenu={headerMenu} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <FacilityGroupPeriodsFilter table={table} />
        <NewTable<LockedPeriodFragment>
          table={table}
          length={periods.length}
          loading={loadingLockedPeriods}
          headerMenu={true}
        />
        {pages > 0 && <CustomPagination total={pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default FacilityGroupPeriods;
