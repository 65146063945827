
const FacilityPeriodStory: React.FC = () => {
    return (
        <div className='flex shadow-smena rounded bg-smena_white p-4 gap-5'>
            <div className="Subtitle1">История</div>
        </div>
    );
};

export default FacilityPeriodStory;
