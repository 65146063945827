import dayjs from 'dayjs';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

export const isFutureOrToday = (day: number, dateParam: string | null): boolean => {
  const store = useFacilityReportStore.getState();
  const selectedTimezone = store.selectedTimezone;

  // Используем выбранную таймзону для создания дат
  let referenceDate;
  if (dateParam) {
    referenceDate = selectedTimezone
      ? dayjs(dateParam).tz(selectedTimezone)
      : dayjs(dateParam);
  } else {
    referenceDate = selectedTimezone
      ? dayjs().tz(selectedTimezone)
      : dayjs();
  }

  const currentDate = selectedTimezone
    ? dayjs().tz(selectedTimezone)
    : dayjs();

  const dayDate = referenceDate.date(day);

  return !dayDate.isBefore(currentDate, 'day');
};

export const isFutureDay = (day: number, dateParam: string | null): boolean => {
  const store = useFacilityReportStore.getState();
  const selectedTimezone = store.selectedTimezone;

  // Используем выбранную таймзону для создания дат
  let referenceDate;
  if (dateParam) {
    referenceDate = selectedTimezone
      ? dayjs(dateParam).tz(selectedTimezone)
      : dayjs(dateParam);
  } else {
    referenceDate = selectedTimezone
      ? dayjs().tz(selectedTimezone)
      : dayjs();
  }

  const currentDate = selectedTimezone
    ? dayjs().tz(selectedTimezone)
    : dayjs();

  const dayDate = referenceDate.date(day);

  return dayDate.isAfter(currentDate, 'day');
};
