import dayjs from 'dayjs';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import { useState, useEffect } from 'react';
import { useLockWorkSheetPeriodMutation } from 'pages/facilityReport/api/mutations/generated/LockWorkSheetPeriod';
import { useParams, useSearchParams } from 'react-router-dom';
import { errorToast, successToast } from 'utils/helpers/notify';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { DayOrNullArray } from 'interfaces/CustomTypes';

const SelectPeriod: React.FC = () => {
    const { facilityId } = useParams();
    const [params] = useSearchParams();
    const dateParam = params.get('date');
    const [currentDate, setCurrentDate] = useState(dayjs(dateParam));
    const [showPeriodPicker, setShowPeriodPicker] = useState(false);
    const [lockWorkSheetPeriod] = useLockWorkSheetPeriodMutation({
        onCompleted: () => {
            successToast('Период успешно заблокирован');
        },
        onError: (error) => {
            errorToast(error.message);
        },
        awaitRefetchQueries: true,
        refetchQueries: [GetSupervisorWorkSheetDocument],
    });

    const { selectedPeriod, setSelectedPeriod, lockedPeriods } = useFacilityReportStore();

    const [isPickerOpen, setIsPickerOpen] = useState(false);

    useEffect(() => {
        if (dateParam) {
            const newDate = dayjs(dateParam);
            setCurrentDate(newDate);
        }
    }, [dateParam]);

    useEffect(() => {
        if (selectedPeriod && (selectedPeriod[0] || selectedPeriod[1])) {
            setShowPeriodPicker(true);
        }
    }, [selectedPeriod]);

    const isDateLocked = (date: dayjs.Dayjs) => {
        if (!lockedPeriods?.length) return false;

        return lockedPeriods.some((period: { startDate: string; endDate: string }) =>
            date.isSameOrAfter(dayjs(period.startDate), 'day') &&
            date.isSameOrBefore(dayjs(period.endDate), 'day')
        );
    };

    const handleDateChange = (dates: DayOrNullArray) => {
        if (!dates || !dates[0] || !dates[1]) {
            setSelectedPeriod([null, null]);
            return;
        }

        const [startDate, endDate] = dates;
        const today = dayjs().startOf('day');

        if (startDate.isSameOrAfter(today) || endDate.isSameOrAfter(today)) {
            errorToast('Нельзя выбрать текущий или будущие дни');
            setSelectedPeriod([null, null]);
            return;
        }

        if (isDateLocked(startDate) || isDateLocked(endDate)) {
            errorToast('Выбранный период содержит заблокированные дни');
            setSelectedPeriod([null, null]);
            return;
        }

        setSelectedPeriod(dates);
    };

    const handleSubmit = async () => {
        if (!selectedPeriod || !selectedPeriod[0] || !selectedPeriod[1] || !facilityId) return;

        try {
            await lockWorkSheetPeriod({
                variables: {
                    input: {
                        facilityId,
                        startDate: selectedPeriod[0].format('YYYY-MM-DD'),
                        endDate: selectedPeriod[1].format('YYYY-MM-DD')
                    }
                }
            });
            setShowPeriodPicker(false);
            setSelectedPeriod([null, null]);
        } catch (error) {
            console.error('Error locking worksheet period:', error);
        }
    };

    const handleCancel = () => {
        setShowPeriodPicker(false);
        setSelectedPeriod([null, null]);
    };

    return (
        <div className="flex items-center gap-2">
            {showPeriodPicker ? (
                <>
                    <div className="flex-1">
                        <RangePickerInput
                            picker="date"
                            value={selectedPeriod}
                            onChange={handleDateChange}
                            maxDate={dayjs().subtract(1, 'day')}
                            minDate={dayjs('2020-01-01')}
                            placeholder={['Начало', 'Конец']}
                            format="DD.MM.YYYY"
                            open={isPickerOpen}
                            onOpenChange={setIsPickerOpen}
                            defaultPickerValue={[currentDate.startOf('month'), currentDate.add(1, 'month').startOf('month')]}
                            disabledDate={(current) => {
                                if (!current) return false;

                                const isFutureOrToday = current.isSameOrAfter(dayjs().startOf('day'));

                                const isLocked = isDateLocked(current);

                                return isFutureOrToday || isLocked;
                            }}
                        />
                    </div>
                    <button
                        className="btn-primary"
                        disabled={!selectedPeriod || !selectedPeriod[0] || !selectedPeriod[1]}
                        onClick={handleSubmit}
                    >
                        Отправить
                    </button>
                    <button
                        className="btn-secondary"
                        onClick={handleCancel}
                    >
                        Отменить
                    </button>
                </>
            ) : (
                <button
                    className="btn-primary"
                    onClick={() => setShowPeriodPicker(true)}
                >
                    Выбрать период на выплату
                </button>
            )}
        </div>
    );
};

export default SelectPeriod;
