import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import dayjs from 'dayjs';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { Link } from 'react-router-dom';
import { getUserFIO } from 'utils/helpers/userFullName';
import ViewScheduleFormElement from 'pages/request/components/editRequest/sidebar/viewElement';
import { getLunchDuration } from 'utils/helpers/lists/lunchDurationTypes';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { ShiftStage } from 'generated/graphql';
import EditShiftButtons from './editShiftButtons';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Plus from 'assets/icons/plus';

dayjs.extend(utc);
dayjs.extend(timezone);

const EditShiftSidebar = () => {
  const { showEditShiftSidebar, setShowEditShiftSidebar, shift, setShowCreateRequestShiftSidebar } = useStore();
  const { user, selectedTimezone } = useFacilityReportStore();

  const formatDateWithTimezone = (date: string | undefined) => {
    if (!date) return '';
    return dayjs(date).tz(selectedTimezone).format(TIME_DATE);
  };

  return (
    <MainSidebar
      title={'Смена'}
      titleClassName="px-5"
      show={showEditShiftSidebar}
      setShow={setShowEditShiftSidebar}
      className="!px-0 !pb-0"
    >
      <div className="flex flex-col min-h-[calc(100vh-120px)]">
        <div className="flex-1 flex flex-col gap-y-6 px-5">
          <div className="Subtitle1 flex gap-x-1">
            <span>График смены:</span>
            <span className="text-smena_text-secondary">
              {dayjs(shift?.dateFrom).format(RU_DATE)} – {dayjs(shift?.dateEnd).format(RU_DATE)}
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span className="H4">{user?.workPost?.position.name}</span>
            <div className="flex gap-x-2 Subtitle2 text-smena_text-secondary">
              <ViewScheduleFormElement data={dayjs(shift?.dateFrom).format(RU_DATE)} newData={false} />
              <span>·</span>
              <ViewScheduleFormElement
                data={`${formatDateWithTimezone(shift?.dateFrom)} – ${formatDateWithTimezone(shift?.dateEnd)}`}
              />
              <span>·</span>
              <ViewScheduleFormElement data={getLunchDuration(shift?.lunchDuration)} />
            </div>
          </div>

          <div className="flex items-center gap-x-1">
            <span className="Subtitle1">Исполнитель:</span>
            {user?.user ? (
              <Link className="text-primary" to={`/users/${user.user?.id}`}>
                {user?.user.firstname && user?.user.lastname
                  ? getUserFIO(user.user)
                  : convertPhoneNumberToNormal(user?.user.phone)}
              </Link>
            ) : (
              <span className={'text-smena_text-secondary'}>отсутствует</span>
            )}
          </div>

          {shift?.stage === ShiftStage.Refused && <div className="text-smena_red">Смена отменена</div>}
          {shift?.stage === ShiftStage.Ineligible && <div className="text-smena_red">Смена отклонена</div>}

          <EditShiftButtons />
        </div>

        <div className="mt-auto border-t border-smena_gray-30">
          <button
            type="button"
            className="px-4 pt-4 w-full text-left text-primary Button1 flex items-center justify-between"
            onClick={() => {
              setShowEditShiftSidebar(false);
              setShowCreateRequestShiftSidebar(true);
            }}
          >
            Добавить новую смену
            <Plus className={'text-primary'} />
          </button>
        </div>
      </div>
    </MainSidebar>
  );
};

export default EditShiftSidebar;
