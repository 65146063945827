import clsx from 'clsx';
import dayjs from 'dayjs';
import { TIME_DATE } from 'utils/helpers/constVariables';
import Tooltip from 'antd/lib/tooltip';
import { useStore } from 'store';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { shiftStatus } from 'utils/helpers/shiftStatus';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const ShiftTitle = () => {
  const { shift } = useStore() as any;
  const { user, selectedTimezone } = useFacilityReportStore();

  return (
    <div className="flex cursor-pointer items-center px-5  mb-4">
      <div className="Button1 mr-[10px]">
        <span className="mr-1">Смена</span>
        <span>{dayjs(shift?.dateFrom).tz(selectedTimezone).format(TIME_DATE)} – </span>
        <span>{dayjs(shift?.dateEnd).tz(selectedTimezone).format(TIME_DATE)}</span>
      </div>
      <Tooltip placement={'top'} title={''}>
        <div className={clsx('shiftModerationStatus relative mr-1', shift ? shiftStatus(shift).bg : null)}>
          <span className="Body2 text-smena_text">{shift ? shiftStatus(shift).text : null}</span>
        </div>
      </Tooltip>

      {shift?.isProbation && (
        <Tooltip
          placement={'top'}
          title={`c ${dayjs(user.workPost?.position.probationStart).format('DD MMMM')} по ${dayjs(
            user.workPost?.position.probationEnd,
          ).format('DD MMMM')}`}
        >
          <div className={'Body2 shiftModerationStatus bg-smena_purple-light relative'}>Проб. п.</div>
        </Tooltip>
      )}
    </div>
  );
};

export default ShiftTitle;
