import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeArrestedPaymentMutationVariables = Types.Exact<{
  input: Types.ChangeArrestedPaymentInput;
}>;


export type ChangeArrestedPaymentMutation = { __typename?: 'Mutation', changeArrestedPayment: { __typename?: 'ModerationArrestedPaymentEntity', id: string, createdAt: any, updatedAt?: any | null } };


export const ChangeArrestedPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeArrestedPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeArrestedPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeArrestedPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode;
export type ChangeArrestedPaymentMutationFn = Apollo.MutationFunction<ChangeArrestedPaymentMutation, ChangeArrestedPaymentMutationVariables>;

/**
 * __useChangeArrestedPaymentMutation__
 *
 * To run a mutation, you first call `useChangeArrestedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeArrestedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeArrestedPaymentMutation, { data, loading, error }] = useChangeArrestedPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeArrestedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ChangeArrestedPaymentMutation, ChangeArrestedPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeArrestedPaymentMutation, ChangeArrestedPaymentMutationVariables>(ChangeArrestedPaymentDocument, options);
      }
export type ChangeArrestedPaymentMutationHookResult = ReturnType<typeof useChangeArrestedPaymentMutation>;
export type ChangeArrestedPaymentMutationResult = Apollo.MutationResult<ChangeArrestedPaymentMutation>;
export type ChangeArrestedPaymentMutationOptions = Apollo.BaseMutationOptions<ChangeArrestedPaymentMutation, ChangeArrestedPaymentMutationVariables>;