import { DayjsOrNull, undefStr } from 'interfaces/CustomTypes';
import { useCreateShiftInModerationMutation } from 'pages/moderationReport/api/mutations/generated/CreateShiftInModeration';
import { errorHandler } from 'utils/helpers/errorHandler';
import { isInt } from 'utils/helpers/isInt';
import { successToast, errorToast } from 'utils/helpers/notify';
import { timeDifference } from 'utils/helpers/timeDifference';
import dayjs from 'dayjs';
import { useStore } from 'store';
import { useParams } from 'react-router';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useSearchParams } from 'react-router-dom';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { UnitType } from 'generated/graphql';

interface Payment {
  sum: string;
  cause: string;
  comment: string;
}

interface Props {
  startTime: DayjsOrNull;
  endTime: DayjsOrNull;
  shiftDuration: DayjsOrNull;
  lunchDuration: number;
  hourRate: string;
  toPayment?: boolean;
  toPaymentBonus?: boolean;
  value: string;
  reason: undefStr;
  comment: undefStr;
  showComment: boolean;
  arrestedPayments: Payment[];
  salaryPayments: Payment[];
}

export const useCreateForm = () => {
  const { facilityId } = useParams();
  const [params] = useSearchParams();
  const dateParam = params.get('date');
  const { user, selectedDay, selectedTimezone } = useFacilityReportStore();
  const [createShift, { loading }] = useCreateShiftInModerationMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const { setShowSidebar } = useStore();

  const initialValues: Props = {
    startTime: null,
    endTime: null,
    shiftDuration: null,
    lunchDuration: 0,
    hourRate: '',
    toPayment: true,
    toPaymentBonus: true,
    value: '',
    reason: undefined,
    comment: undefined,
    showComment: true,
    arrestedPayments: [{ sum: '', cause: '', comment: '' }],
    salaryPayments: [{ sum: '', cause: '', comment: '' }],
  };

  const validate = (values: Props) => {
    const errors: any = {};
    if (!values.startTime) {
      errors.startTime = 'Обязательное поле';
    }

    if (!values.endTime) {
      errors.endTime = 'Обязательное поле';
    }

    if (!+values.hourRate) {
      errors.hourRate = 'Обязательное поле';
    }

    // Проверяем каждое удержание
    values.arrestedPayments.forEach((payment, index) => {
      if (payment.sum || payment.cause || payment.comment) {
        if (!payment.sum) {
          errors[`arrestedPayments.${index}.sum`] = 'Обязательное поле';
        }
        if (!payment.cause) {
          errors[`arrestedPayments.${index}.cause`] = 'Обязательное поле';
        }
        if (!payment.comment) {
          errors[`arrestedPayments.${index}.comment`] = 'Обязательное поле';
        }
      }
    });

    // Проверяем каждую надбавку
    values.salaryPayments.forEach((payment, index) => {
      if (payment.sum || payment.cause || payment.comment) {
        if (!payment.sum) {
          errors[`salaryPayments.${index}.sum`] = 'Обязательное поле';
        }
        if (!payment.cause) {
          errors[`salaryPayments.${index}.cause`] = 'Обязательное поле';
        }
        if (!payment.comment) {
          errors[`salaryPayments.${index}.comment`] = 'Обязательное поле';
        }
      }
    });

    return errors;
  };

  const getFormattedDateTime = (time: DayjsOrNull, isEndTime: boolean = false, startTime: DayjsOrNull = null) => {
    if (!time) return null;

    const [year, month] = dateParam?.split('-') || [dayjs().year().toString(), (dayjs().month() + 1).toString()];
    const day = selectedDay ?? dayjs().date();

    const adjustedDay = isEndTime && startTime && time.hour() < startTime.hour() ? day + 1 : day;

    return dayjs
      .tz(
        `${year}-${month.padStart(2, '0')}-${String(adjustedDay).padStart(2, '0')}T${String(time.hour()).padStart(2, '0')}:${String(
          time.minute(),
        ).padStart(2, '0')}:00`,
        selectedTimezone,
      )
      .toISOString();
  };

  const onSubmit = (values: Props) => {
    return new Promise<void>((resolve, reject) => {
      if (!user?.user?.id) {
        reject();
        return;
      }

      const dateFrom = getFormattedDateTime(values.startTime);
      const shiftEndDate = getFormattedDateTime(values.endTime, true, values.startTime);

      if (!dateFrom || !shiftEndDate) {
        errorToast('Необходимо указать время начала и окончания смены');
        reject();
        return;
      }

      const duration = values.shiftDuration
        ? values.shiftDuration.hour() * 60 + values.shiftDuration.minute()
        : values.endTime && values.startTime
          ? dayjs(shiftEndDate).diff(dayjs(dateFrom), 'minutes')
          : 0;

      const newArrestedPayments = values.arrestedPayments
        .filter(payment => payment.sum || payment.cause || payment.comment)
        .map(payment => ({
          sum: payment.sum ? Number(payment.sum) : 0,
          cause: payment.cause,
          comment: payment.comment,
        }));

      const newSalaryPayments = values.salaryPayments
        .filter(payment => payment.sum || payment.cause || payment.comment)
        .map(payment => ({
          sum: payment.sum ? Number(payment.sum) : 0,
          cause: payment.cause,
          comment: payment.comment,
        }));

      createShift({
        variables: {
          moderation: {
            value: user?.workPost?.position?.unit === UnitType.Hour ? 0 : Number(values.value),
            hourRate: isInt(parseFloat(values.hourRate)),
            duration,
            cause: values.reason,
            comment: values.comment,
            toPayment: values.toPayment,
            toPaymentBonus: values.toPaymentBonus,
            arrestedPayments: newArrestedPayments,
            salaryPayments: newSalaryPayments,
          },
          shift: {
            userId: user.user.id,
            facilityId: facilityId || '',
            positionId: user.workPost?.position.id,
            dateFrom,
            shiftEndDate,
            duration: String(timeDifference(values.startTime, values.endTime)),
            lunchDuration: values.lunchDuration,
          },
        },
      })
        .then(() => {
          successToast('Смена успешно добавлена');
          setShowSidebar(false);
          resolve();
        })
        .catch((error: Error) => {
          errorToast(errorHandler(error));
          reject(error);
        });
    });
  };

  return { initialValues, onSubmit, validate, loading };
};
