import { Formik, Form } from 'formik';
import { useUpdateShiftPaymentBonus } from './hooks';
import { useStore } from 'store';
import clsx from 'clsx';
import { RU_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import Loader from 'ui/loader';
import CheckCircleFill12 from 'assets/icons/CheckCircleFill12';

const ShiftBonus = () => {
  const { shift } = useStore() as any;
  const { initialValues, onSubmit, validate, loading } = useUpdateShiftPaymentBonus();

  return (
    <div>
      <div className="sidebar__shift gap-y-3 shadow-smena rounded-lg mb-4">
        <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit} enableReinitialize>
          {({ values, setFieldValue }) => (
            <Form className={'flex flex-col'}>
              <p className="Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30">Бонусы</p>

              {shift?.promotionResults &&
                shift?.promotionResults.map((item: any, index: any) => (
                  <div key={index} className={'px-4 pt-4 flex gap-x-2 w-full'}>
                    <div className="Subtitle2 flex flex-1 items-center overflow-hidden">
                      <p className='after:content-[""] after:bg-smena_gray-40 step-title relative'>
                        {item.promotion.name}{' '}
                        <span className="Body2 text-smena_text-secondary pr-4">+ {item.promotion.rewardSum} ₽/ч.</span>
                      </p>
                    </div>
                    <div className="Table-small flex flex-0 items-center overflow-hidden">
                      <p
                        className={clsx(
                          item.progress >= item.promotion.requirementShiftCount ? 'text-smena_green-dark' : '',
                          'pl-4 flex gap-x-1 items-center',
                        )}
                      >
                        {item.progress >= item.promotion.requirementShiftCount
                          ? item.promotion.requirementShiftCount
                          : item.progress}
                        /{item.promotion.requirementShiftCount} <CheckCircleFill12 />
                      </p>
                    </div>
                  </div>
                ))}

              <div className="Subtitle2 px-4 py-4">
                К оплате:{' '}
                <span className="Body2 text-smena_text-secondary pr-4">+ {shift?.bonusForThisShift || 0} ₽</span>
              </div>

              <div className="grid grid-cols-2 gap-y-1 p-4 border-y border-smena_gray-30">
                <label className={clsx('rounded-l-lg radio-block', { 'radio-block_active': values.toPaymentBonus })}>
                  <input
                    type="radio"
                    name="toPaymentBonus"
                    className="radio radio_approve"
                    checked={values.toPaymentBonus}
                    onChange={() => setFieldValue('toPaymentBonus', true)}
                    disabled={loading}
                  />
                  К оплате
                </label>
                <label className={clsx('radio-block rounded-r-lg', { 'radio-block_active': !values.toPaymentBonus })}>
                  <input
                    type="radio"
                    name="toPaymentBonus"
                    className="radio radio_reject"
                    checked={!values.toPaymentBonus}
                    onChange={() => setFieldValue('toPaymentBonus', false)}
                    disabled={loading}
                  />
                  Без оплаты
                </label>
              </div>

              <div className="flex justify-between items-center px-4 py-4">
                <div className="Caption text-smena_text-secondary flex gap-x-1">
                  <span>Посл. изменения:</span>
                  <span>{dayjs(shift?.updatedAt).format(RU_DATE)}</span>
                </div>
                <button type="submit" className="btn-primary_small" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center gap-x-2 text-smena_gray-30">
                      <Loader />
                      <span>Сохранение</span>
                    </div>
                  ) : (
                    'Сохранить'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ShiftBonus;
