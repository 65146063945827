import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useCreateArrestedPaymentMutation } from 'pages/facilityReport/api/mutations/generated/CreateArrestedPayment';
import { useDeleteArrestedPaymentMutation } from 'pages/facilityReport/api/mutations/generated/DeleteArrestedPayment';
import { useChangeArrestedPaymentMutation } from 'pages/facilityReport/api/mutations/generated/ChangeArrestedPayment';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { successToast, errorToast } from 'utils/helpers/notify';

interface Props {
  sum: string;
  cause?: string;
  comment?: string;
}

export const useHoldForm = () => {
  const [createArrestedPayment, { loading }] = useCreateArrestedPaymentMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const [deleteArrestedPayment] = useDeleteArrestedPaymentMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const [changeArrestedPayment] = useChangeArrestedPaymentMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const { shift, setShift } = useStore() as any;

  const initialValues: Props = {
    sum: '',
    cause: '',
    comment: '',
  };

  const validate = (values: Props) => {
    const errors: any = {};

    if (!values.sum) {
      errors.sum = 'Обязательное поле';
    }

    if (values.cause === '') {
      errors.cause = 'Обязательное поле';
    }
    if (values.comment === '') {
      errors.comment = 'Обязательное поле';
    }

    return errors;
  };

  const onSubmit = (values: Props) => {
    const newSum = values.sum ? Number(values.sum) : 0;

    return createArrestedPayment({
      variables: {
        input: {
          moderationId: shift.moderation.id,
          sum: newSum,
          cause: values.cause || '',
          comment: values.comment || '',
        },
      },
    })
      .then(res => {
        if (res.data?.createArrestedPayment) {
          const newPayment = {
            id: res.data.createArrestedPayment.id,
            sum: newSum,
            cause: values.cause || '',
            comment: values.comment || '',
            createdAt: res.data.createArrestedPayment.createdAt,
            updatedAt: res.data.createArrestedPayment.updatedAt,
          };

          setShift({
            ...shift,
            moderation: {
              ...shift.moderation,
              arrestedPayments: [...(shift.moderation.arrestedPayments || []), newPayment],
            },
          });
          successToast('Удержание добавлено');
          return true;
        }
        return false;
      })
      .catch(e => {
        errorToast(errorHandler(e));
        return false;
      });
  };

  const handleDelete = (id: string) => {
    return deleteArrestedPayment({
      variables: {
        input: {
          id,
        },
      },
    })
      .then(() => {
        const newArrestedPayments = shift.moderation.arrestedPayments.filter(
          (payment: any) => payment.id !== id
        );

        setShift({
          ...shift,
          moderation: {
            ...shift.moderation,
            arrestedPayments: newArrestedPayments,
          },
        });
        successToast('Удержание удалено');
        return true;
      })
      .catch(e => {
        errorToast(errorHandler(e));
        return false;
      });
  };

  const handleEdit = (id: string, values: Props) => {
    const newSum = values.sum ? Number(values.sum) : 0;

    return changeArrestedPayment({
      variables: {
        input: {
          id,
          sum: newSum,
        },
      },
    })
      .then(() => {
        setShift({
          ...shift,
          moderation: {
            ...shift.moderation,
            arrestedPayments: shift.moderation.arrestedPayments.map((payment: any) =>
              payment.id === id ? { ...payment, sum: newSum } : payment
            ),
          },
        });
        successToast('Удержание изменено');
        return true;
      })
      .catch(e => {
        errorToast(errorHandler(e));
        return false;
      });
  };

  return { initialValues, onSubmit, validate, loading, handleDelete, handleEdit };
};
