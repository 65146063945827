import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import React, { useMemo, useState } from 'react';
import { successToast } from 'utils/helpers/notify';
import FacilityCell from 'pages/users/components/ourUsers/facilityCell';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import CopyIcon from 'assets/icons/CopyIcon';
import Actions from './users/actions';
import { getPersonalInfoIcon } from 'pages/users/lib/getPersonalInfoIcon';
import { showContracts } from './users/contracts';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import Tooltip from 'antd/es/tooltip';
import CitizenshipWithFlag from 'components/citizenshipFlag';
import { CountryCode } from 'libphonenumber-js';
import ArmeniaIcon from 'assets/icons/flags/armeniaIcon';
import BelarusIcon from 'assets/icons/flags/belarusIcon';
import KazakhstanIcon from 'assets/icons/flags/kazakhstanIcon';
import KyrgystanIcon from 'assets/icons/flags/kyrgystanIcon';
import RussiaIcon from 'assets/icons/flags/russiaIcon';
import UkraineIcon from 'assets/icons/flags/ukraine';
import AzerbaijanIcon from 'assets/icons/flags/azerbaijan';
import GeorgiaIcon from 'assets/icons/flags/georgia';
import MoldovaIcon from 'assets/icons/flags/moldova';
import TajikistanIcon from 'assets/icons/flags/tajikistan';
import TurkmenistanIcon from 'assets/icons/flags/turkmenistan';
import UzbekistanIcon from 'assets/icons/flags/uzbekistan';
import SearchCheck from 'assets/icons/searchCheck';
import { WarningExclamation } from 'assets/icons/WarningExclamation';
import { Union } from 'assets/icons/Union';

const optionsWithIcons = [
  { id: 'AM', name: 'Армения', icon: <ArmeniaIcon /> },
  { id: 'BY', name: 'Беларусь', icon: <BelarusIcon /> },
  { id: 'KZ', name: 'Казахстан', icon: <KazakhstanIcon /> },
  { id: 'KG', name: 'Кыргызстан', icon: <KyrgystanIcon /> },
  { id: 'RU', name: 'Россия', icon: <RussiaIcon /> },
  { id: 'UA', name: 'Украина', icon: <UkraineIcon /> },
  { id: 'GE', name: 'Грузия', icon: <GeorgiaIcon /> },
  { id: 'UZ', name: 'Узбекистан', icon: <UzbekistanIcon /> },
  { id: 'TM', name: 'Туркменистан', icon: <TurkmenistanIcon /> },
  { id: 'MD', name: 'Молдавия', icon: <MoldovaIcon /> },
  { id: 'TJ', name: 'Таджикистан', icon: <TajikistanIcon /> },
  { id: 'AZ', name: 'Азербайджан', icon: <AzerbaijanIcon /> },
];

export const useUsersTable = (data: UsersPaginatedFragment[]) => {
  const columns: ColumnDef<UsersPaginatedFragment>[] = useMemo(() => {
    return [
      {
        id: 'lastname',
        header: 'Фамилия',
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/users/${original.id}`} className="link">
              {original.lastname ? original.lastname : 'Нет информации'}
            </Link>
          );
        },
      },
      {
        id: 'firstname',
        header: 'Имя',
        size: 150,
        minSize: 150,
        accessorKey: 'firstname',
      },
      {
        id: 'patronymic',
        header: 'Отчество',
        size: 150,
        minSize: 150,
        accessorKey: 'patronymic',
      },
      {
        id: 'role',
        header: 'Роль',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => getRoleRu(original.role),
      },
      {
        id: 'phone',
        header: 'Телефон',
        minSize: 185,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
              <span>{convertPhoneNumberToNormal(original.phone)}</span>
              <Tooltip title={'Скопировать'}>
                <button
                  className={'group-hover/number:block hidden'}
                  onClick={() => {
                    navigator.clipboard.writeText(original.phone).then(() => {
                      successToast('Номер скопирован', 1000);
                    });
                  }}
                >
                  <CopyIcon />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        id: 'citizenship',
        header: 'Гражданство',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) => {
          const citizenshipCode = original.citizenship?.code as CountryCode;
          const citizenshipName = optionsWithIcons.find(option => option.id === citizenshipCode)?.name;

          return (
            <div className="flex gap-2">
              <CitizenshipWithFlag code={citizenshipCode} name={citizenshipName} />
            </div>
          );
        },
      },
      {
        id: 'personalInfo',
        header: 'Перс. данные',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex justify-center'}>{getPersonalInfoIcon(original.registrationState?.personalInfo)}</div>
          );
        },
      },
      {
        id: 'taxPartnerInfo',
        header: 'Мой налог',
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex justify-center'}>
              {original.registrationState?.taxPartnerInfo ? (
                <Check className={'text-smena_green'} />
              ) : (
                <Across className={'text-smena_red'} />
              )}
            </div>
          );
        },
      },
      {
        id: 'paymentInfo',
        header: 'Платеж. Данные',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex justify-center'}>
              {original.registrationState?.paymentInfo ? (
                <Check className={'text-smena_green'} />
              ) : (
                <Across className={'text-smena_red'} />
              )}
            </div>
          );
        },
      },
      {
        id: 'medicalBook',
        header: 'Мед. книжка',
        size: 120,
        minSize: 120,
        cell: ({ row: { original } }) => {
          const medicalPages = ['PAGE2_3', 'PAGE28_29'];

          const hasMedBookRequired = original.workposts?.some(workpost => {
            const casings = workpost.facility?.casings;
            if (!casings) return false;
            return casings.some(casing => casing.isMedBookRequired);
          });

          if (!hasMedBookRequired) {
            return (
              <Tooltip title="Не требуется">
                <div className="flex justify-center">
                  <Union />
                </div>
              </Tooltip>
            );
          }

          const hasAllDocuments = original.documents?.filter(d => medicalPages.includes(d.type)).length >= 2;
          if (!hasAllDocuments) {
            return (
              <Tooltip title="Не заполнено">
                <div className="flex justify-center">
                  <WarningExclamation />
                </div>
              </Tooltip>
            );
          }

          const documentReviews = original.allDocumentsReviews?.filter(dr => medicalPages.includes(dr.type)) || [];
          const hasAllReviews = documentReviews.length >= 2;
          if (!hasAllReviews) {
            return (
              <Tooltip title="Требует проверки">
                <div className="flex justify-center">
                  <SearchCheck />
                </div>
              </Tooltip>
            );
          }

          const hasRejected = documentReviews.some(dr => dr.status === 'REJECTED');
          if (hasRejected) {
            return (
              <Tooltip title="Отклонено">
                <div className="flex justify-center">
                  <Across className="text-smena_red" />
                </div>
              </Tooltip>
            );
          }

          const allAccepted = documentReviews.every(dr => dr.status === 'ACCEPTED');
          if (allAccepted) {
            return (
              <Tooltip title="Принято">
                <div className="flex justify-center">
                  <Check className="text-smena_green" />
                </div>
              </Tooltip>
            );
          }

          return null;
        },
      },
      {
        id: 'facility',
        header: 'Объект',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return <FacilityCell user={original} />;
        },
      },
      {
        id: 'contracts',
        header: 'Документ',
        cell: ({ row: { original } }) => {
          return showContracts(original.contracts);
        },
      },
      {
        id: 'currentVersion',
        header: 'Версия',
        cell: ({ row: { original } }) => original.currentVersion,
      },
      {
        id: 'isActive',
        header: 'Статус',
        size: 96,
        minSize: 96,
        cell: ({ row: { original } }) => {
          return original.isActive ? <Check className={'text-smena_green'} /> : <Across className="text-smena_red" />;
        },
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => <Actions original={original} />,
      },
    ];
  }, []);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
    meta: {
      lastname: true,
      requisiteUpdatedAt: true,
      personalInfo: true,
      taxPartnerInfo: true,
      paymentInfo: true,
      version: true,
      currentVersion: true,
    },
  });
};
