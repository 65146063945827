import DataPicker from '../facilityReportFilter/components/datePicker';
import Timezone from './timezone';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetSupervisorWorkSheetReportLazyQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheetReport';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import dayjs from 'dayjs';

const FacilityReportHeader: React.FC = () => {
  const { facilityId } = useParams();

  const [params] = useSearchParams();
  const dateParam = params.get('date');

  const { positionIds, userIds, doNotShowShifts, order, orderBy, selectedTimezone } = useFacilityReportStore();
  const [getSupervisorWorkSheetReport] = useGetSupervisorWorkSheetReportLazyQuery({
    onCompleted: data => {
      if (data.getSupervisorWorkSheetReport?.path) {
        const fileUrl = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${data.getSupervisorWorkSheetReport.path}`;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl.split('/').pop() || 'report.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  });

  const downloadReport = () => {
    getSupervisorWorkSheetReport({
      variables: {
        input: {
          facilityId: facilityId || '',
          month: dayjs(dateParam).month() + 1 || dayjs().month() + 1,
          year: dayjs(dateParam).year() || dayjs().year(),
          timezone: selectedTimezone,
          doNotShowShifts,
          order,
          orderBy,
          positionIds,
          userIds,
        },
      },
    });
  };


  return (
    <div className="p-4 flex justify-between items-center border-b-[1px]">
      <div className="flex gap-6">
        <DataPicker />
        <Timezone />
      </div>


      <button className="btn-primary" onClick={downloadReport}>
        Скачать отчёт
      </button>
    </div>
  );
};

export default FacilityReportHeader;
