import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSalaryPaymentMutationVariables = Types.Exact<{
  input: Types.CreateSalaryPaymentInput;
}>;


export type CreateSalaryPaymentMutation = { __typename?: 'Mutation', createSalaryPayment: { __typename?: 'ModerationSalaryPaymentEntity', id: string, createdAt: any, updatedAt?: any | null } };


export const CreateSalaryPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSalaryPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateSalaryPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSalaryPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode;
export type CreateSalaryPaymentMutationFn = Apollo.MutationFunction<CreateSalaryPaymentMutation, CreateSalaryPaymentMutationVariables>;

/**
 * __useCreateSalaryPaymentMutation__
 *
 * To run a mutation, you first call `useCreateSalaryPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalaryPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalaryPaymentMutation, { data, loading, error }] = useCreateSalaryPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalaryPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalaryPaymentMutation, CreateSalaryPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalaryPaymentMutation, CreateSalaryPaymentMutationVariables>(CreateSalaryPaymentDocument, options);
      }
export type CreateSalaryPaymentMutationHookResult = ReturnType<typeof useCreateSalaryPaymentMutation>;
export type CreateSalaryPaymentMutationResult = Apollo.MutationResult<CreateSalaryPaymentMutation>;
export type CreateSalaryPaymentMutationOptions = Apollo.BaseMutationOptions<CreateSalaryPaymentMutation, CreateSalaryPaymentMutationVariables>;