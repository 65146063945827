import OutsideClick from 'components/outsideComponent';
import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Table } from '@tanstack/react-table';
import Across from 'assets/icons/Across';
import Row from './Row';
import TableSettings from 'assets/icons/TableSettings';
import Tooltip from 'antd/es/tooltip';
import { createPortal } from 'react-dom';

interface Props<T> {
  table: Table<T>;
  title?: string;
}

const ColumnSettings = <T,>({ table, title = 'Настройка столбцов' }: Props<T>) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [showColumnSettings, setShowColumnSettings] = useState(false);
  const onColumnSettingsClick = () => {
    setShowColumnSettings(value => !value);
  };
  const onReset = () => {
    table.reset();
  };

  const renderDropdown = () => {
    if (!showColumnSettings) return null;

    const rect = btnRef.current?.getBoundingClientRect();
    if (!rect) return null;

    return createPortal(
      <OutsideClick
        show={showColumnSettings}
        setShow={setShowColumnSettings}
        triggerRef={btnRef}
        className={clsx(
          'fixed flex flex-col p-5 bg-smena_white rounded-lg shadow-smena z-50 w-[305px] transition-all',
          showColumnSettings ? 'opacity-100' : 'opacity-0 pointer-events-none',
        )}
        style={{
          top: `${rect.bottom + 8}px`,
          left: `${rect.left}px`
        }}
      >
        <button
          className="absolute top-[15px] right-[15px]"
          onClick={() => {
            setShowColumnSettings(false);
          }}
        >
          <Across className="text-smena_gray-90" />
        </button>
        <h4 className='text-smena_gray-90 text-base font-medium'>Настройка столбцов</h4>
        {table.getAllLeafColumns().map(column => {
          if (column.id === 'checkbox') return;
          return <Row key={column.id} column={column} table={table} />;
        })}
        <button className={'btn-stroke mt-6'} onClick={onReset}>
          Сбросить
        </button>
      </OutsideClick>,
      document.body
    );
  };

  return (
    <div className={'relative'}>
      <Tooltip title={title}>
        <button
          className={'border border-opacity-30 border-primary rounded-lg p-1 w-[32px] h-[32px]'}
          ref={btnRef}
          onClick={onColumnSettingsClick}
        >
          <TableSettings className={'fill-current text-primary'} />
        </button>
      </Tooltip>
      {renderDropdown()}
    </div>
  );
};

export default ColumnSettings;
