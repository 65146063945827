import dayjs from 'dayjs';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { getPeriodStatusBadge } from 'utils/helpers/constVariables/periodStatuses';

interface Props {
    period: any;
    selfEmployedTaxRate?: number;
}

const FacilityPeriodHeader: React.FC<Props> = ({ period, selfEmployedTaxRate = 0.93 }) => {
    const safeAmount = period?.totalAmount ? Number(period.totalAmount) : 0;
    const isSafeAmountValid = !isNaN(safeAmount);

    const statusBadge = getPeriodStatusBadge(period?.status);

    return (
        <div className='flex shadow-smena rounded bg-smena_white mb-8 p-4 gap-5'>
            <div>
                <div className="Subtitle2">Номер</div>
                <div className='Body2'>{period?.erpId || 'Не указан'}</div>
            </div>
            <div>
                <div className="Subtitle2">Период</div>
                <div className='Body2'>
                    {period?.facility ? (
                        <Link
                            to={`/facilities/${period.facility.id}/tabel/?date=${dayjs(period.startDate).format('YYYY-MM-DD')}`}
                            className='link'
                        >
                            {dayjs(period?.startDate).format('DD.MM.YYYY')} - {dayjs(period?.endDate).format('DD.MM.YYYY')}
                        </Link>
                    ) : (
                        `${dayjs(period?.startDate).format('DD.MM.YYYY')} - ${dayjs(period?.endDate).format('DD.MM.YYYY')}`
                    )}
                </div>
            </div>
            <div>
                <div className="Subtitle2">Дата создания</div>
                <div className='Body2'>{dayjs(period?.createdAt).format('DD.MM.YYYY')}</div>
            </div>
            <div>
                <div className="Subtitle2">Автор</div>
                <div className='Body2'>
                    {period?.author ? (
                        <Link to={`/users/${period?.author.id}`} className='link'>
                            {`${period?.author.firstname} ${period?.author.lastname}`}
                        </Link>
                    ) : '-'}
                </div>
            </div>
            <div>
                <div className="Subtitle2">Сумма, ₽</div>
                <div className='Body2'>
                    {isSafeAmountValid
                        ? `${safeAmount.toFixed(2)} ₽`
                        : `${period?.totalAmount || '0.00'} ₽`}
                </div>
            </div>
            <div>
                <div className="Subtitle2">Сумма с НПД, ₽</div>
                <div className='Body2'>
                    {isSafeAmountValid
                        ? `${Number(Math.round((safeAmount / selfEmployedTaxRate) * 100) / 100).toFixed(2)} ₽`
                        : '0.00 ₽'}
                </div>
            </div>
            <div>
                <div className="Subtitle2">Статус</div>
                <div className='Body2'>
                    {period?.status ? (
                        <span className={clsx(
                            'px-2 py-1 rounded-full text-xs',
                            statusBadge.className
                        )}>
                            {statusBadge.text}
                        </span>
                    ) : (
                        '-'
                    )}
                </div>
            </div>
            <div>
                <div className="Subtitle2">Дата исполнения</div>
                <div className='Body2'>{period?.dateOfExecution ? dayjs(period?.dateOfExecution).format('DD.MM.YYYY') : '-'}</div>
            </div>
        </div>
    );
};

export default FacilityPeriodHeader;
