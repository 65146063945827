import { useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { allRoleArray, superWithoutNewbie } from 'utils/helpers/lists/roleArray';
import Plus from 'assets/icons/plus';

import Filter from 'components/table/filters/filter';
import clsx from 'clsx';
import { activeOptions } from 'utils/helpers/constVariables';
import SimpleSelect from 'ui/select';
import { useMeRole } from 'store/hooks/useMeRole';
import { DocumentsStatus, Role } from 'generated/graphql';
import TableFilter from 'components/table/filters';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useCustomSearchParams } from '../../lib/useParams';
import Search from 'ui/search';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import ColumnSettings from 'components/table/columnSettings';
import { Table } from '@tanstack/react-table';
import { useFacilityById } from 'utils/api/useFacility';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { userPersonalStatusesList } from '../../lib/userPersonalStatusesList';
import { contractStatuses } from '../../../userContacts/utils/consts';
import { userTaxPartnerInfoList } from '../../lib/userTaxPartnerInfoList';
import { userPaymentInfoList } from '../../lib/userPaymentInfoList';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import ArmeniaIcon from 'assets/icons/flags/armeniaIcon';
import BelarusIcon from 'assets/icons/flags/belarusIcon';
import KazakhstanIcon from 'assets/icons/flags/kazakhstanIcon';
import KyrgystanIcon from 'assets/icons/flags/kyrgystanIcon';
import RussiaIcon from 'assets/icons/flags/russiaIcon';
import UnknownIcon from 'assets/icons/flags/unknownIcon';
import AzerbaijanIcon from 'assets/icons/flags/azerbaijan';
import GeorgiaIcon from 'assets/icons/flags/georgia';
import MoldovaIcon from 'assets/icons/flags/moldova';
import TajikistanIcon from 'assets/icons/flags/tajikistan';
import TurkmenistanIcon from 'assets/icons/flags/turkmenistan';
import UkraineIcon from 'assets/icons/flags/ukraine';
import UzbekistanIcon from 'assets/icons/flags/uzbekistan';
import { useCountries } from 'utils/api/useCountries';
// import { userMedicalBooksStatusesList } from 'pages/users/lib/userMedicalBooksStatusesList';

const optionsWithIcons = [
  { id: 'AM', name: 'Армения', icon: <ArmeniaIcon /> },
  { id: 'BY', name: 'Беларусь', icon: <BelarusIcon /> },
  { id: 'KZ', name: 'Казахстан', icon: <KazakhstanIcon /> },
  { id: 'KG', name: 'Кыргызстан', icon: <KyrgystanIcon /> },
  { id: 'RU', name: 'Россия', icon: <RussiaIcon /> },
  { id: 'UA', name: 'Украина', icon: <UkraineIcon /> },
  { id: 'GE', name: 'Грузия', icon: <GeorgiaIcon /> },
  { id: 'UZ', name: 'Узбекистан', icon: <UzbekistanIcon /> },
  { id: 'TM', name: 'Туркменистан', icon: <TurkmenistanIcon /> },
  { id: 'MD', name: 'Молдавия', icon: <MoldovaIcon /> },
  { id: 'TJ', name: 'Таджикистан', icon: <TajikistanIcon /> },
  { id: 'AZ', name: 'Азербайджан', icon: <AzerbaijanIcon /> },
  { id: 'unknown', name: 'Не определено', icon: <UnknownIcon /> },
];

interface Props {
  table: Table<UsersPaginatedFragment>;
}

const UsersFilters = ({ table }: Props) => {
  const { countries } = useCountries();

  const citizenshipOptions = useMemo(
    () =>
      optionsWithIcons.map(option => {
        const country = countries.find(country => country.code === option.id);
        return {
          ...option,
          id: country ? country.id : option.id,
        };
      }),
    [countries],
  );

  const location = useLocation();
  const { handleParams, params, setParams, useParamsCount, resetFilter } = useCustomSearchParams();
  const facilityIdParam = params.get('facilityId');
  const facilityGroupIdParam = params.get('facilityGroupId');
  const roleParam = params.get('role');
  const documentStatusParam = params.get('documentStatus');
  const activeParam = params.get('active');
  const personalStatusParam = params.get('personal-status');
  const taxParam = params.get('tax');
  const paymentInfoParam = params.get('payment-info');
  // const medicalBookParam = params.get('medical-book');
  const citizenshipParam = params.get('citizenship');
  const [facilityId, setFacilityId] = useState(facilityIdParam);
  const [facilityGroupId, setFacilityGroupId] = useState(facilityGroupIdParam);
  const [personalStatus, setPersonalStatus] = useState(personalStatusParam);
  const [tax, setTax] = useState(taxParam);
  const [paymentInfo, setPaymentInfo] = useState(paymentInfoParam);
  // const [medicalBook, setMedicalBook] = useState(medicalBookParam);
  const [role, setRole] = useState(roleParam);
  const [citizenshipId, setCitizenhsipId] = useState<null | string | undefined>(citizenshipParam);
  const [isActive, setIsActive] = useState(activeParam);
  const [documentStatus, setDocumentStatus] = useState<string | null | DocumentsStatus>(documentStatusParam);
  const [showFilter, setShowFilter] = useState(false);
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const country = citizenshipOptions.find(country => country.id === citizenshipId);
  const userPersonalStatuses = userPersonalStatusesList();
  const userTaxPartnerInfo = userTaxPartnerInfoList();
  const userPaymentInfo = userPaymentInfoList();
  // const userMedicalBooksStatuses = userMedicalBooksStatusesList();
  const paramsCount = useParamsCount();

  const { facility } = useFacilityById(facilityIdParam);
  const { facilities } = useSelectFacilities();
  const { facilitiesGroups } = useSelectFacilityGroups();
  const filteredFacilities = useMemo(
    () => filterFacilities(facilityGroupId, facilities),
    [facilityGroupId, facilities],
  );
  const btnRef = useRef<HTMLButtonElement>(null);
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeAdmin = useMeRole([Role.Admin]);
  const rolesOptions = useMemo(
    () => (isMeSupervisor ? superWithoutNewbie : allRoleArray).map(el => ({ id: el.value, name: el.name })),
    [isMeSupervisor],
  );
  const documentStatusOptions = useMemo(() => contractStatuses.map(el => ({ id: el.name, name: el.nameRu })), []);
  // const documentOptions = useMemo(() => documentsStatusList.map(el => ({ id: el.value, name: el.name })), []);

  return (
    <div className="filter-bar flex xs:flex-row flex-col xs:flex-wrap gap-y-2 justify-between">
      <div className="flex flex-col gap-y-2">
        <div className={clsx('flex relative gap-x-2')}>
          <Search />
          <div className={'flex gap-x-0.5'}>
            <button
              type="button"
              className={clsx('btn-stroke_small flex gap-x-1 items-center')}
              ref={btnRef}
              onClick={() => {
                setShowFilter(value => !value);
              }}
            >
              Фильтр
              {Boolean(paramsCount) && (
                <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                  {String(paramsCount)}
                </span>
              )}
            </button>
          </div>
          <ColumnSettings<UsersPaginatedFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              popupClassName={'max-w-[300px]'}
              label="Группа"
              onChange={value => {
                setFacilityGroupId(value);
              }}
              value={facilityGroupId}
              placeholder="Не выбрано"
              allOption={'Все'}
              options={facilitiesGroups}
            />
            <SimpleSelect
              label="Объект"
              onChange={value => {
                setFacilityId(value);
              }}
              value={facilityId}
              placeholder="Не выбрано"
              allOption={'Все'}
              options={filteredFacilities}
            />
            <SimpleSelect
              label="Роль"
              sort={false}
              onChange={value => {
                setRole(value);
              }}
              value={role}
              placeholder="Не выбрано"
              allOption={'Все'}
              options={rolesOptions}
            />
            <SimpleSelect
              label="Гражданство"
              onChange={value => {
                setCitizenhsipId(value);
              }}
              value={citizenshipId}
              allOption="Все гражданства"
              placeholder="Не выбрано"
              options={citizenshipOptions}
              showSearch={false}
            />
            <SimpleSelect
              label="Статус перс. данные"
              onChange={value => {
                setPersonalStatus(value);
              }}
              value={personalStatus}
              allOption="Все"
              placeholder="Не выбрано"
              options={userPersonalStatuses}
            />
            <SimpleSelect
              label="Статус моего налога"
              onChange={value => {
                setTax(value);
              }}
              value={tax}
              allOption="Все"
              placeholder="Не выбрано"
              options={userTaxPartnerInfo}
            />
            <SimpleSelect
              label="Статус платежных данных"
              onChange={value => {
                setPaymentInfo(value);
              }}
              value={paymentInfo}
              allOption="Все"
              placeholder="Не выбрано"
              options={userPaymentInfo}
            />
            {/* <SimpleSelect
              label="Статус мед. книжки"
              onChange={value => {
                setMedicalBook(value);
              }}
              value={medicalBook}
              allOption="Все"
              placeholder="Не выбрано"
              options={userMedicalBooksStatuses}
            /> */}
            <SimpleSelect
              label="Статус документов"
              onChange={value => {
                setDocumentStatus(value);
              }}
              value={documentStatus}
              allOption="Все"
              placeholder="Не выбрано"
              options={documentStatusOptions}
            />
            <SimpleSelect
              label="Статус активности"
              onChange={value => {
                setIsActive(value);
              }}
              value={isActive}
              allOption="Все"
              placeholder="Не выбрано"
              options={activeOptions}
            />
            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('facilityGroupId', facilityGroupId);
                  handleParams('facilityId', facilityId);
                  handleParams('role', role);
                  // handleParams('medical-book', medicalBook);
                  handleParams('documentStatus', documentStatus);
                  handleParams('active', isActive);
                  handleParams('personal-status', personalStatus);
                  handleParams('tax', tax);
                  handleParams('payment-info', paymentInfo);
                  handleParams('citizenship', citizenshipId);
                  params.delete('page');
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                Применить
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setFacilityGroupId(null);
                  setFacilityId(null);
                  setRole(null);
                  // setMedicalBook(null);
                  setIsActive(null);
                  setDocumentStatus(null);
                  setPersonalStatus(null);
                  setTax(null);
                  setPaymentInfo(null);
                  setCitizenhsipId(undefined);
                  setParams();
                  setShowFilter(false);
                }}
              >
                <span>Сбросить</span>
              </button>
            </div>
          </TableFilter>
        </div>

        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!facilityGroupIdParam}
              content={facilityGroupName}
              clickHandler={() => {
                setFacilityGroupId(null);
                resetFilter('facilityGroupId');
              }}
            />
            <Filter
              show={!!facilityIdParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />
            <Filter
              show={!!roleParam}
              content={getRoleRu(roleParam)}
              clickHandler={() => {
                setRole(null);
                resetFilter('role');
              }}
            />
            <Filter
              show={!!citizenshipParam}
              content={
                citizenshipId === 'all' ? 'Все гражданства' : citizenshipId === null ? 'Не определено' : country?.name
              }
              clickHandler={() => {
                setCitizenhsipId(undefined);
                resetFilter('citizenship');
              }}
            />
            <Filter
              show={!!personalStatusParam}
              content={userPersonalStatuses.find(el => el.id === personalStatus)?.name}
              clickHandler={() => {
                setPersonalStatus(null);
                resetFilter('personal-status');
              }}
            />
            <Filter
              show={!!taxParam}
              content={userTaxPartnerInfo.find(el => el.id === taxParam)?.name}
              clickHandler={() => {
                setTax(null);
                resetFilter('tax');
              }}
            />
            <Filter
              show={!!paymentInfoParam}
              content={userPaymentInfo.find(el => el.id === paymentInfoParam)?.name}
              clickHandler={() => {
                setPaymentInfo(null);
                resetFilter('payment-info');
              }}
            />
            {/* <Filter
              show={!!medicalBookParam}
              content={userMedicalBooksStatuses.find(el => el.id === medicalBookParam)?.name}
              clickHandler={() => {
                setMedicalBook(null);
                resetFilter('medical-book');
              }}
            /> */}
            <Filter
              show={!!documentStatusParam}
              content={`Статус документов "${
                documentStatusOptions.find(status => status.id === documentStatusParam)?.name
              }"`}
              clickHandler={() => {
                setDocumentStatus(null);
                resetFilter('documentStatus');
              }}
            />
            <Filter
              show={!!activeParam}
              content={activeParam === 'true' ? 'Активный' : 'Не активный'}
              clickHandler={() => {
                setRole(null);
                resetFilter('active');
              }}
            />
          </div>
        )}
      </div>
      {isMeAdmin && !location.pathname.includes('fired') && (
        <div className="flex self-start">
          <Link to="/users/create">
            <button type="button" className="btn-primary_big flex items-center">
              <span className="inline-flex mr-2">
                <Plus className="text-smena_white" />
              </span>
              Новый пользователь
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default UsersFilters;
