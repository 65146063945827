import MainLayout from 'components/layout/main';
import NewTable from 'components/table/newTable';
import FacilityPeriodFilter from './components/FacilityPeriodFilter';
import { useFacilityPeriodTable } from 'components/table/columns/facilityPeriod';
import { useGetLockedPeriodQuery } from './api/queries/generated/GetLockedPeriod';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import FacilityPeriodHeader from './components/FacilityPeriodHeader';
import FacilityPeriodStory from './components/FacilityPeriodStory';
import { useGetCurrentSelfEmployedTaxQuery } from 'pages/facilityGroupCasing/api/query/generated/GetCurrentSelfEmployedTax';
import { useFacilityGroupBreadcrumbs } from 'pages/facilityGroup/hooks/useFacilityGroupBreadcrumbs';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import { useFacility } from 'utils/api/useFacility';
import React from 'react';
import { GetSupervisorWorkSheetOrderByEnum, OrderDirection, SortingWay } from 'generated/graphql';

const FacilityPeriod: React.FC = () => {
  const { periodId } = useParams();
  const [params] = useSearchParams();
  const location = useLocation();
  const { facility } = useFacility();

  const profession = params.get('profession');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay') as SortingWay;

  const facilityBreadCrumbs = useFacilityBreadcrumbs();
  const facilityGroupBreadCrumbs = useFacilityGroupBreadcrumbs();
  
  const breadCrumbs = facility ? facilityBreadCrumbs : facilityGroupBreadCrumbs;

  const { data: currentSelfTaxData } = useGetCurrentSelfEmployedTaxQuery();
  const selfEmployedTaxRate = currentSelfTaxData?.getCurrentSelfEmployedTax || 0.93;

  const sortableFields: Record<string, GetSupervisorWorkSheetOrderByEnum> = {
    executor: GetSupervisorWorkSheetOrderByEnum.Lastname,
    profession: GetSupervisorWorkSheetOrderByEnum.Position
  };

  const {
    data: dataLockedPeriods,
    loading: loadingLockedPeriods,
    error
  } = useGetLockedPeriodQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: periodId || '',
        positionId: profession || undefined,
        searchQuery: searchQuery || undefined,
        orderBy: sortBy ? sortableFields[sortBy] : undefined,
        order: sortWay === SortingWay.Desc ? OrderDirection.Desc : OrderDirection.Asc
      },
    },
  });

  const snapshot = dataLockedPeriods?.getLockedPeriod?.lockedPeriod?.snapshot;
  const lockedPeriod = dataLockedPeriods?.getLockedPeriod?.lockedPeriod;
  const positions = dataLockedPeriods?.getLockedPeriod?.positions;

  const periodTitle = lockedPeriod?.erpId ? `Период на выплату №${lockedPeriod.erpId}` : '';
  const updatedBreadCrumbs = breadCrumbs ? [...breadCrumbs, { name: periodTitle, path: location.pathname }] : [];

  const tableData = React.useMemo(() => 
    snapshot?.map(item => ({
      ...item,
      status: lockedPeriod?.status
    })) || [], 
  [snapshot, lockedPeriod?.status]);
  
  const table = useFacilityPeriodTable(tableData);

  return (
    <MainLayout title={periodTitle} breadCrumbs={updatedBreadCrumbs} error={error}>
      <FacilityPeriodHeader period={lockedPeriod} selfEmployedTaxRate={selfEmployedTaxRate} />
      <div className='grid grid-cols-[1fr_540px] gap-6'>
        <div className='flex flex-col shadow-smena rounded bg-smena_white overflow-hidden relative'>
          <div className='relative z-10'>
            <FacilityPeriodFilter 
              table={table}
              positions={positions}
            />
          </div>
          <div className='flex-1 overflow-auto'>
            <NewTable<any>
              table={table}
              length={tableData.length}
              loading={loadingLockedPeriods}
              headerMenu={true}
            />
          </div>
        </div>
        <FacilityPeriodStory />
      </div>
    </MainLayout>
  );
};

export default FacilityPeriod;
