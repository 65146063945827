import Days from './components/Days';
import Performers from './components/Performers/Performers';
import Statistics from './components/Statistics';

import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
  loading?: boolean;
  lockedPeriods?: Array<{
    id: string;
    startDate: string;
    endDate: string;
  }>;
}

const FacilityTable: React.FC<Props> = ({ data, loading, lockedPeriods }) => {
  const { statisticsVisible } = useFacilityReportStore();

  return (
    <div className="overflow-auto relative isolate" style={{ maxHeight: 'calc(100vh - 358px)' }}>
      <div
        className={`grid w-fit min-w-full ${statisticsVisible ? 'grid-cols-[460px_1fr_1050px]' : 'grid-cols-[460px_1fr]'}`}
      >
        <div className="sticky left-0 z-50 bg-white isolate">
          <Performers data={data} loading={loading} />
        </div>
        <div className="overflow-visible">
          <Days data={data} loading={loading} lockedPeriods={lockedPeriods} />
        </div>
        {statisticsVisible && (
          <div className="sticky right-0 z-50 bg-white isolate">
            <Statistics data={data} loading={loading} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FacilityTable;
