import dayjs from 'dayjs';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import ShiftCell from './components/ShiftCell/ShiftCell';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import CustomShiftCell from './components/CustomShiftCell';
import { isFutureDay, isFutureOrToday } from 'pages/facilityReport/lib/helpers/isFutureOrToday';
import DeleteObj from 'assets/icons/DeleteObj';
import Tooltip from 'antd/lib/tooltip'; import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SkeletonLayout from 'components/table/Skeleton';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
  loading?: boolean;
  lockedPeriods?: Array<{
    id: string;
    startDate: string;
    endDate: string;
  }>;
}

const Days = (props: Props) => {
  const { facilityId } = useParams();
  const { data, loading, lockedPeriods } = props;
  const [params] = useSearchParams();

  const dateParam = params.get('date') || dayjs().format('YYYY-MM-DD');

  const { isCalendar, setIsCalendar, selectedRow, selectedPeriod, setSelectedPeriod } = useFacilityReportStore();

  const currentDay = dayjs().date();

  const daysArray = useMemo(() => Array.from({ length: dayjs(dateParam).daysInMonth() }, (_, i) => i + 1), [dateParam]);

  const daysColumns = daysArray.map(day => ({
    name: `day-${day}`,
    className: clsx(
      'w-[70px] h-[36px] flex justify-center items-center px-2 text-center border-r-[1px] border-b-[1px] border-smena_gray-30',
      {
        'border-l-[2px] border-r-[2px] !border-b-[1px] border-x-primary':
          currentDay === day &&
          dayjs().month() === dayjs(dateParam).month() &&
          dayjs().year() === dayjs(dateParam).year(),
      },
    ),
    style: { width: 70, minWidth: 70 },
    Header: day.toString(),
    sorted: false,
  }));

  const totalRowColumns = daysArray.map(day => ({
    name: `total-${day}`,
    className: clsx(
      'w-[70px] h-[36px] flex justify-center items-center px-2 text-center border-r-[1px] border-b-[1px] border-smena_gray-30',
      {
        'border-l-[2px] border-r-[2px] !border-b-[1px] border-x-primary':
          currentDay === day &&
          dayjs().month() === dayjs(dateParam).month() &&
          dayjs().year() === dayjs(dateParam).year(),
      },
    ),
    style: { width: 70, minWidth: 70 },
    Header: '',
    sorted: false,
  }));

  const getDayFromShift = (shiftDate: string) => {
    const date = useFacilityReportStore.getState().getDateInTimezone(shiftDate);
    return date.date();
  };

  useEffect(() => {
    if (dateParam) {
      setIsCalendar(false);
    }
  }, [dateParam, setIsCalendar]);

  const groupedData = data?.reduce(
    (acc, item) => {
      const professionId = item.workPost.position?.id;

      const key = `${professionId}-${item.user.id}`;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Record<string, typeof data>,
  );

  const isUserUnassigned = (
    userItem: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'][number],
    day: number,
  ) => {
    if (!userItem.user.userExperience) return false;

    const currentFacilityExps = userItem.user.userExperience.filter(
      exp => exp.facilityId === facilityId && userItem.workPost.position?.id === exp.positionId,
    );

    const currentDate = dayjs(dateParam).date(day);

    const assignedExp = currentFacilityExps.find(item => item.assignedAt && !item.unassignAt);
    let isAssigned = currentFacilityExps.some(item => item.unassignAt === null);

    if (assignedExp && !dayjs(assignedExp.assignedAt).isSame(currentDate, 'month')) {
      isAssigned = false;
    }

    if (isAssigned) return false;

    const lastUnasignedExpCurrentMonth = currentFacilityExps
      .filter(item => item.unassignAt !== null && dayjs(item.unassignAt).isSame(currentDate, 'month'))
      .sort((a, b) => new Date(b.unassignAt).valueOf() - new Date(a.unassignAt).valueOf())[0];

    if (!lastUnasignedExpCurrentMonth || !dateParam) return false;

    const unassignDate = dayjs(lastUnasignedExpCurrentMonth.unassignAt);

    return (
      unassignDate.isSame(currentDate, 'date') &&
      unassignDate.isSame(currentDate, 'month') &&
      unassignDate.isSame(currentDate, 'year')
    );
  };

  const isAfterUnassignment = (
    userItem: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'][number],
    day: number,
  ) => {
    if (!userItem.user.userExperience) return false;

    const currentFacilityExps = userItem.user.userExperience.filter(
      exp => exp.facilityId === facilityId && userItem.workPost.position?.id === exp.positionId,
    );

    const currentDate = dayjs(dateParam).date(day);

    const assignedExp = currentFacilityExps.find(item => item.assignedAt && !item.unassignAt); // тянучка
    let isAssigned = currentFacilityExps.some(item => item.unassignAt === null);

    if (assignedExp && !dayjs(assignedExp.assignedAt).isSame(currentDate, 'month')) {
      // тянучка
      isAssigned = false; // тянучка
    } else if (assignedExp && dayjs(assignedExp.assignedAt).isSame(currentDate, 'month')) {
      // тянучка
      return dayjs(assignedExp.assignedAt).date() > day; // тянучка
    } // тянучка

    if (isAssigned) return false;

    const lastUnasignedExpCurrentMonth = currentFacilityExps
      .filter(item => item.unassignAt !== null && dayjs(item.unassignAt).isSame(currentDate, 'month'))
      .sort((a, b) => new Date(b.unassignAt).valueOf() - new Date(a.unassignAt).valueOf())[0];

    if (!lastUnasignedExpCurrentMonth || !dateParam) return false;

    const unassignDate = dayjs(lastUnasignedExpCurrentMonth.unassignAt);

    return Boolean(
      currentDate.isAfter(unassignDate, 'day') &&
      currentDate.month() === unassignDate.month() &&
      currentDate.year() === unassignDate.year(),
    );
  };

  const isDateLocked = (day: number) => {
    if (!lockedPeriods?.length) return false;

    const currentDate = dayjs(dateParam).date(day);
    return lockedPeriods.some(period =>
      currentDate.isSameOrAfter(dayjs(period.startDate), 'day') &&
      currentDate.isSameOrBefore(dayjs(period.endDate), 'day')
    );
  };

  const handleDayClick = (day: number) => {
    if (isDateLocked(day) || isFutureOrToday(day, dateParam)) return;

    const baseDate = dayjs(dateParam).startOf('month');
    const clickedDate = baseDate.date(day);

    if (!selectedPeriod || !selectedPeriod[0]) {
      setSelectedPeriod([clickedDate, null]);
    } else if (!selectedPeriod[1]) {
      let startDate = selectedPeriod[0];
      let endDate = clickedDate;

      if (clickedDate.isBefore(selectedPeriod[0])) {
        startDate = clickedDate;
        endDate = selectedPeriod[0];
      }

      const hasLockedPeriodsBetween = lockedPeriods?.some(period => {
        const periodStart = dayjs(period.startDate);
        const periodEnd = dayjs(period.endDate);

        return (
          (periodStart.isSameOrAfter(startDate, 'day') && periodStart.isSameOrBefore(endDate, 'day')) ||
          (periodEnd.isSameOrAfter(startDate, 'day') && periodEnd.isSameOrBefore(endDate, 'day')) ||
          (periodStart.isBefore(startDate, 'day') && periodEnd.isAfter(endDate, 'day'))
        );
      });

      if (hasLockedPeriodsBetween) {
        const nearestLockedDate = lockedPeriods?.reduce((nearest, period) => {
          const periodStart = dayjs(period.startDate);
          if (periodStart.isSameOrAfter(startDate, 'day') && periodStart.isBefore(endDate, 'day')) {
            return periodStart.isBefore(nearest) ? periodStart : nearest;
          }
          return nearest;
        }, endDate);

        if (nearestLockedDate) {
          endDate = nearestLockedDate.subtract(1, 'day');
        }
      }

      setSelectedPeriod([startDate, endDate]);
    } else {
      setSelectedPeriod([clickedDate, null]);
    }
  };

  return (
    <div className="h-full">
      <div className="sticky top-0 z-10 bg-smena_gray-5">
        <div
          className="grid border-b-[1px] border-smena_gray-30"
          style={{ gridTemplateColumns: `repeat(${daysArray.length}, 70px)` }}
        >
          {daysArray.map(day => {
            const currentDate = dayjs(dateParam).date(day);
            const isInRange = selectedPeriod && selectedPeriod[0] &&
              (!selectedPeriod[1] ?
                currentDate.isSame(selectedPeriod[0], 'day') :
                currentDate.isSameOrAfter(selectedPeriod[0], 'day') &&
                currentDate.isSameOrBefore(selectedPeriod[1], 'day'));

            const isRangeStart = selectedPeriod?.[0] && currentDate.isSame(selectedPeriod[0], 'day');
            const isRangeEnd = selectedPeriod?.[1] && currentDate.isSame(selectedPeriod[1], 'day');
            const isLocked = isDateLocked(day);
            const isFutureOrTodayDate = isFutureDay(day, dateParam);

            return (
              <div
                key={day}
                onClick={() => handleDayClick(day)}
                className={clsx(
                  'pt-2 pb-2 text-center border-r-[1px] border-smena_gray-30 font-medium h-[36px] Table cursor-pointer',
                  {
                    'border-l-[2px] border-r-[2px] border-l-primary border-r-primary bg-primary-extra_light text-primary':
                      currentDay === day &&
                      dayjs().month() === dayjs(dateParam).month() &&
                      dayjs().year() === dayjs(dateParam).year(),
                    'bg-primary-extra_extra_light text-primary': isInRange && !isLocked && !isFutureOrTodayDate,
                    'border-l-[2px] border-l-primary': isRangeStart && !isLocked && !isFutureOrTodayDate,
                    'border-r-[2px] border-r-primary': isRangeEnd && !isLocked && !isFutureOrTodayDate,
                    'cursor-not-allowed [background-image:repeating-linear-gradient(-45deg,rgba(115,115,115,0.2),rgba(115,115,115,0.2)_2px,transparent_2px,transparent_8px)]': isLocked,
                  }
                )}
              >
                {day}
              </div>
            );
          })}
        </div>
      </div>

      {loading && <SkeletonLayout columns={daysColumns} rows={5} />}

      <div className="overflow-x-auto">
        <div className="w-fit">
          {groupedData &&
            Object.entries(groupedData).map(([, users], index) =>
              users.map((userItem, userIndex) => {
                return (
                  <div
                    className="grid border-b-[1px] Body2"
                    style={{ gridTemplateColumns: `repeat(${daysArray.length}, 70px)` }}
                    key={index + userIndex}
                  >
                    {daysArray.map(day => {
                      const shiftForDay = userItem.shifts?.find(
                        (shift: any) => getDayFromShift(shift.dateFrom) === day,
                      );
                      const isUnassigned = isUserUnassigned(userItem, day);
                      const isAfterUnassign = isAfterUnassignment(userItem, day);

                      const currentDate = dayjs(dateParam).date(day);
                      const isRangeStart = selectedPeriod?.[0] && currentDate.isSame(selectedPeriod[0], 'day');
                      const isRangeEnd = selectedPeriod?.[1] && currentDate.isSame(selectedPeriod[1], 'day');
                      const isLocked = isDateLocked(day);

                      if (isUnassigned) {
                        return (
                          <Tooltip title="Исполнитель откреплен" key={`${index}-${day}`}>
                            <div
                              className={clsx(
                                'pt-2 pb-2 text-center border-r-[1px] font-medium h-[36px] flex justify-center',
                                {
                                  ['border-l-[2px] border-r-[2px] border-primary']:
                                    currentDay === day &&
                                    dayjs().month() === dayjs(dateParam).month() &&
                                    dayjs().year() === dayjs(dateParam).year(),
                                },
                              )}
                            >
                              <DeleteObj />
                            </div>
                          </Tooltip>
                        );
                      }

                      if (isAfterUnassign) {
                        return (
                          <div
                            key={`${index}-${day}`}
                            className={clsx(
                              'pt-2 pb-2 text-center border-r-[1px] font-medium h-[36px] flex justify-center items-center text-smena_gray-30',
                              {
                                ['border-l-[2px] border-r-[2px] border-primary']:
                                  currentDay === day &&
                                  dayjs().month() === dayjs(dateParam).month() &&
                                  dayjs().year() === dayjs(dateParam).year(),
                              },
                            )}
                          >
                            ✕
                          </div>
                        );
                      }

                      if (isCalendar && !shiftForDay && index === selectedRow && isFutureDay(day, dateParam)) {
                        return (
                          <CustomShiftCell
                            groupIndex={index}
                            userIndex={userIndex}
                            dayIndex={day}
                            key={`${index}-${day}`}
                          />
                        );
                      }

                      return (
                        <ShiftCell
                          key={`${index}-${day}`}
                          shift={shiftForDay}
                          supervisor={userItem}
                          dayIndex={day}
                          rowIndex={userIndex}
                          groupIndex={index}
                          isDisabled={isLocked}
                          className={clsx({
                            'border-l-[2px] border-l-primary': isRangeStart && !isLocked,
                            'border-r-[2px] border-r-primary': isRangeEnd && !isLocked,
                            '[background-image:repeating-linear-gradient(-45deg,rgba(115,115,115,0.2),rgba(115,115,115,0.2)_2px,transparent_2px,transparent_8px)]': isLocked
                          })}
                        />
                      );
                    })}
                  </div>
                );
              }),
            )}

          {loading ? (
            <SkeletonLayout columns={totalRowColumns} rows={1} />
          ) : (
            <div
              className="grid border-b-[1px] Body2"
              style={{ gridTemplateColumns: `repeat(${daysArray.length}, 70px)` }}
            >
              {daysArray.map(day => {
                const shiftCount = data?.reduce((count, supervisor) => {
                  const shiftsForDay = supervisor.shifts?.filter(
                    (shift: any) => getDayFromShift(shift.dateFrom) === day,
                  );
                  return count + Number(shiftsForDay?.length);
                }, 0);

                return (
                  <div
                    key={day}
                    onClick={() => { }}
                    className={clsx('pt-2 pb-2 text-center border-r-[1px] font-medium h-[36px]', {
                      ['border-l-[2px] border-r-[2px] border-l-primary border-r-primary']:
                        currentDay === day &&
                        dayjs().month() === dayjs(dateParam).month() &&
                        dayjs().year() === dayjs(dateParam).year(),
                    })}
                  >
                    {Number(shiftCount) > 0 ? shiftCount : 0}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Days;
