import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteArrestedPaymentMutationVariables = Types.Exact<{
  input: Types.DeleteArrestedPaymentInput;
}>;


export type DeleteArrestedPaymentMutation = { __typename?: 'Mutation', deleteArrestedPayment: boolean };


export const DeleteArrestedPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteArrestedPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteArrestedPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteArrestedPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteArrestedPaymentMutationFn = Apollo.MutationFunction<DeleteArrestedPaymentMutation, DeleteArrestedPaymentMutationVariables>;

/**
 * __useDeleteArrestedPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteArrestedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArrestedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArrestedPaymentMutation, { data, loading, error }] = useDeleteArrestedPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArrestedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArrestedPaymentMutation, DeleteArrestedPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArrestedPaymentMutation, DeleteArrestedPaymentMutationVariables>(DeleteArrestedPaymentDocument, options);
      }
export type DeleteArrestedPaymentMutationHookResult = ReturnType<typeof useDeleteArrestedPaymentMutation>;
export type DeleteArrestedPaymentMutationResult = Apollo.MutationResult<DeleteArrestedPaymentMutation>;
export type DeleteArrestedPaymentMutationOptions = Apollo.BaseMutationOptions<DeleteArrestedPaymentMutation, DeleteArrestedPaymentMutationVariables>;