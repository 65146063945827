import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState, useCallback } from 'react';
import { getPeriodStatusBadge } from 'utils/helpers/constVariables/periodStatuses';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useGetCurrentSelfEmployedTaxQuery } from 'pages/facilityGroupCasing/api/query/generated/GetCurrentSelfEmployedTax';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { useSearchParams } from 'react-router-dom';
import { SortingWay } from 'generated/graphql';
import Up from 'assets/icons/sort/up';
import Down from 'assets/icons/sort/down';

const SortableHeader = ({
    title,
    columnId,
    sortBy,
    sortWay,
    onSort
}: {
    title: string;
    columnId: string;
    sortBy: string | null;
    sortWay: SortingWay | null;
    onSort: (columnId: string) => void
}) => {
    const isActive = sortBy === columnId;
    const isDesc = isActive && sortWay === SortingWay.Desc;
    const isAsc = isActive && sortWay === SortingWay.Asc;

    return (
        <div
            className="flex items-center gap-x-2 cursor-pointer"
            onClick={() => onSort(columnId)}
        >
            <span>{title}</span>
            <span className="flex flex-col gap-y-1">
                <Up className={isAsc ? 'text-smena_text-helper' : 'text-smena_text-disabled'} />
                <Down className={isDesc ? 'text-smena_text-helper' : 'text-smena_text-disabled'} />
            </span>
        </div>
    );
};

export const useFacilityPeriodTable = (
    data: any[],
) => {
    const { data: currentSelfTax } = useGetCurrentSelfEmployedTaxQuery();
    const [params, setParams] = useSearchParams();
    const sortBy = params.get('sortBy');
    const sortWay = params.get('sortWay') as SortingWay;

    const handleSorting = useCallback((columnId: string) => {
        if (sortBy === columnId) {
            if (!sortWay || sortWay === SortingWay.Asc) {
                params.set('sortWay', SortingWay.Desc);
                setParams(params);
            } else {
                params.set('sortWay', SortingWay.Asc);
                setParams(params);
            }
        } else {
            params.set('sortBy', columnId);
            params.set('sortWay', SortingWay.Desc);
            setParams(params);
        }
    }, [sortBy, sortWay, params, setParams]);
    
    const columns: ColumnDef<any>[] = useMemo(() => {
        return [
            {
                id: 'executor',
                headerName: 'Исполнитель',
                header: () => (
                    <SortableHeader
                        title="Исполнитель"
                        columnId="executor"
                        sortBy={sortBy}
                        sortWay={sortWay}
                        onSort={handleSorting}
                    />
                ),
                size: 200,
                enableSorting: true,
                cell: ({ row: { original } }) => {
                    const user = original.user;
                    if (!user) return '-';
                    
                    const fullName = user.lastname || user.firstname || user.patronymic
                        ? `${user.lastname || ''} ${user.firstname || ''} ${user.patronymic || ''}`.trim()
                        :  convertPhoneNumberToNormal(user.phone);

                    return (
                        <Link to={`/users/${user.id}`} className='link'>
                            {fullName}
                        </Link>
                    );
                },
            },
            {
                id: 'profession',
                headerName: 'Профессия',
                header: () => (
                    <SortableHeader
                        title="Профессия"
                        columnId="profession"
                        sortBy={sortBy}
                        sortWay={sortWay}
                        onSort={handleSorting}
                    />
                ),
                size: 200,
                enableSorting: true,
                cell: ({ row: { original } }) => {
                    return original.workPost?.position?.name || '-';
                },
            },
            {
                id: 'shifts',
                header: 'Смены',
                size: 200,
                cell: ({ row: { original } }) => {
                    return <div className="text-right">{original.shifts?.length || 0}</div>;
                },
            },
            {
                id: 'fact',
                header: 'Факт',
                size: 200,
                cell: ({ row: { original } }) => {
                    const analytics = original.analytics;
                    if (!analytics) return '-';

                    const unit = analytics.unit;
                    let content;

                    if (unit === 'HOUR' || !unit) {
                        content = formatTime(analytics.workTimeFact || 0);
                    } else if (unit === 'PIECE') {
                        content = `${analytics.pieceFact || 0} шт.`;
                    } else if (unit === 'POINT') {
                        content = `${analytics.pointFact || 0}%`;
                    } else {
                        content = formatTime(analytics.workTimeFact || 0);
                    }

                    return <div className="text-right">{content}</div>;
                },
            },
            {
                id: 'rate',
                header: 'По ставке',
                size: 200,
                cell: ({ row: { original } }) => {
                    return <div className="text-right">{formatMoney(original.analytics?.shiftsPayment || 0)}</div>;
                },
            },
            {
                id: 'bonus',
                header: 'Бонусы',
                size: 200,
                cell: ({ row: { original } }) => {
                    return <div className="text-right">{formatMoney(original.analytics?.bonusPayment || 0)}</div>;
                },
            },
            {
                id: 'hold',
                header: 'Удержания',
                size: 200,
                cell: ({ row: { original } }) => {
                    return <div className="text-right">{formatMoney(original.analytics?.arrestedPayment || 0)}</div>;
                },
            },
            {
                id: 'surcharge',
                header: 'Надбавки',
                size: 200,
                cell: ({ row: { original } }) => {
                    return <div className="text-right">{formatMoney(original.analytics?.salaryPayment || 0)}</div>;
                },
            },
            {
                id: 'sum',
                header: 'Сумма',
                size: 200,
                cell: ({ row: { original } }) => {
                    const analytics = original.analytics || {};
                    const shiftsPayment = analytics.shiftsPayment || 0;
                    const salaryPayment = analytics.salaryPayment || 0;
                    const arrestedPayment = analytics.arrestedPayment || 0;
                    const bonusPayment = analytics.bonusPayment || 0;

                    return <div className="text-right">
                        {formatMoney(shiftsPayment + salaryPayment + bonusPayment - arrestedPayment)}
                    </div>;
                },
            },
            {
                id: 'sumWithNpd',
                header: 'Сумма с НПД',
                size: 200,
                cell: ({ row: { original } }) => {
                    const analytics = original.analytics || {};
                    const shiftsPayment = analytics.shiftsPayment || 0;
                    const salaryPayment = analytics.salaryPayment || 0;
                    const arrestedPayment = analytics.arrestedPayment || 0;
                    const bonusPayment = analytics.bonusPayment || 0;
                    const totalAmount = shiftsPayment + salaryPayment + bonusPayment - arrestedPayment;
                    
                    const amount = totalAmount
                        ? Math.round((totalAmount / Number(currentSelfTax?.getCurrentSelfEmployedTax)) * 100) / 100
                        : 0;
                    
                    return <div className="text-right">{formatMoney(amount)}</div>;
                },
            },
            {
                id: 'status',
                header: 'Статус',
                size: 200,
                cell: () => {
                    const statusBadge = getPeriodStatusBadge('Неизвестно');
                    return (
                        <span className={clsx(
                            'px-2 py-1 rounded-full text-xs',
                            statusBadge.className
                        )}>
                            {statusBadge.text}
                        </span>
                    );
                },
            },
        ];
    }, [currentSelfTax?.getCurrentSelfEmployedTax, sortBy, sortWay, handleSorting]);

    const [columnOrder, setColumnOrder] = useState(
        columns.map(column => column.id as string)
    );

    return useReactTable({
        data,
        state: {
            columnOrder,
            sorting: sortBy ? [{ id: sortBy, desc: sortWay === SortingWay.Desc }] : [],
        },
        onColumnOrderChange: setColumnOrder,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        columns,
    });
};

export const formatTime = (decimalHours: number) => {
    if (!decimalHours && decimalHours !== 0) return '-';

    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ч.`;
};

export const formatMoney = (value: number) => {
    return Math.round(value * 100) / 100;
};

