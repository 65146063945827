import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useFacility } from 'utils/api/useFacility';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useEffect } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);

const cleanTimezone = (timezone?: string | null) => {
  if (!timezone) return undefined;
  const match = timezone.match(/^[A-Za-z]+\/[A-Za-z_]+/);
  return match ? match[0] : undefined;
};

const Timezone = () => {
  const { facility } = useFacility();
  const { setIsUsingFacilityTime } = useFacilityReportStore();

  useEffect(() => {
    if (!facility?.id) return;

    const facilityTimezone = cleanTimezone(facility?.city?.timeZone);

    console.log('Таймзона объекта:', facilityTimezone);

    if (facilityTimezone) {
      setIsUsingFacilityTime(true, facilityTimezone);

      const timeSettings = {
        isUsingFacilityTime: true,
        timezone: facilityTimezone
      };
      sessionStorage.setItem(`facilityTimeSettings_${facility.id}`, JSON.stringify(timeSettings));

      console.log('Установлена таймзона объекта:', facilityTimezone);
    } else {
      console.warn('Не удалось получить таймзону объекта!');
    }
  }, [facility?.id, facility?.city?.timeZone, setIsUsingFacilityTime]);

  const localTime = dayjs();
  const facilityTimezone = cleanTimezone(facility?.city?.timeZone);
  const facilityTime = facilityTimezone ? dayjs().utc().tz(facilityTimezone) : dayjs();

  const isSameTimezone = localTime.format('Z') === facilityTime.format('Z');

  if (isSameTimezone) return null;

  return (
    <div className="flex gap-4 items-center">
      <div className="Subtitle2 text-smena_text-secondary">
        Время объекта:{' '}
        <span className="text-smena_text">
          {facilityTime.format('HH:mm')} (GMT{facilityTime.format('Z')})
        </span>
      </div>
    </div>
  );
};

export default Timezone;
