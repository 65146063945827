import React, { useState } from 'react';
import SelectArrow from 'assets/icons/SelectArrow';
import clsx from 'clsx';
import { userNameSurname } from 'utils/helpers/userFullName';
import dayjs from 'dayjs';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { useReadAllCommentsInShiftMutation } from 'pages/moderationReport/api/mutations/generated/ReadAllCommentsInShift';
import { useStore } from 'store';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';

const ShiftComments = React.memo(() => {
  const { shift } = useStore() as any;

  const [readAllCommentsInShift] = useReadAllCommentsInShiftMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });

  const [isReadComments, setIsReadComments] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const isNewComments = shift?.newCommentsNum > 0;

  const handleCommentsVisible = () => {
    setShowComments(prevState => !prevState);

    if (!showComments && isNewComments) {
      readAllCommentsInShift({ variables: { input: { shiftId: shift.id } } });
      setIsReadComments(true);
    }
  };
  return (
    <>
      {shift?.comments?.length ? (
        <>
          <div className="px-4 col-span-2 flex flex-col gap-2 cursor-pointer">
            <div className="flex items-center gap-x-1" onClick={handleCommentsVisible}>
              <span className="Caption-numbers text-primary">Комментарий от бригадира</span>
              {isNewComments && !isReadComments && (
                <span className={clsx('Tag px-[5px] rounded-full bg-smena_red text-smena_white')}>
                  {shift?.newCommentsNum}
                </span>
              )}

              <span className={`transform transition-transform ${showComments && 'rotate-180'}`}>
                <SelectArrow className="text-primary" />
              </span>
            </div>
            {showComments
              ? shift?.comments.map((comment: any) => {
                  return (
                    <div className="col-span-full flex gap-y-0.5 flex-col overflow-hidden break-words" key={comment.id}>
                      <div className="flex gap-x-2 justify-between">
                        {userNameSurname(comment.author)}
                        <p>{dayjs(comment.createdAt).format(`${RU_DATE} ${TIME_DATE}`)}</p>
                      </div>
                      <p className={'Table-small text-smena_text-secondary'}>{comment.message}</p>
                    </div>
                  );
                })
              : null}
          </div>
        </>
      ) : null}
    </>
  );
});

export default ShiftComments;
