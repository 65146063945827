import dayjs from 'dayjs';
import { useShiftConditions } from 'pages/moderationReport/lib/hooks';
import { useStore } from 'store';
import { TIME_DATE } from 'utils/helpers/constVariables';
import { durationHoursAndMinutes } from 'utils/helpers/durationHoursAndMinutes';
import { isInt } from 'utils/helpers/isInt';
import { showTime } from 'utils/helpers/showTime';
import { padZeroTime } from 'utils/helpers/timeObject';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const ShiftPlan = () => {
  const { shift } = useStore() as any;
  const { selectedTimezone } = useFacilityReportStore();

  const { unitSymbol, isShiftUnitHour } = useShiftConditions(shift || {});

  return (
    <div className="grid grid-cols-3 gap-y-4 gap-x-[10px] border-b border-b-smena_gray-5 p-4">
      <div className="col-span-full flex gap-x-1 Subtitle2">
        <span className="text-smena_text">План:</span>
        <span className="text-smena_text-secondary">
          {isShiftUnitHour
            ? `${padZeroTime(durationHoursAndMinutes(shift?.durationPlan)).hours}:${
                padZeroTime(durationHoursAndMinutes(shift?.durationPlan)).minutes
              }`
            : isInt(shift?.value)}
          {unitSymbol}
        </span>
      </div>
      <div className="col-span-1 grid">
        <span className="Subtitle2 text-smena_text">Начало смены</span>
        <span className="Table-small text-smena_text-secondary">
          {dayjs(shift?.dateFrom).tz(selectedTimezone).format(TIME_DATE)}
        </span>
      </div>
      <div className="col-span-1 grid">
        <span className="Subtitle2 text-smena_text">Конец смены</span>
        <span className="Table-small text-smena_text-secondary">
          {dayjs(shift?.dateEnd).tz(selectedTimezone).format(TIME_DATE)}
        </span>
      </div>
      <div className="col-span-1 grid">
        <span className="Subtitle2 text-smena_text">Обед</span>
        <span className="Table-small text-smena_text-secondary">
          {shift?.lunchDuration ? showTime(durationHoursAndMinutes(Number(shift?.lunchDuration))) : 'Без обеда'}
        </span>
      </div>
    </div>
  );
};

export default ShiftPlan;
