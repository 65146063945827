import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useCreateSalaryPaymentMutation } from 'pages/facilityReport/api/mutations/generated/CreateSalaryPayment';
import { useDeleteSalaryPaymentMutation } from 'pages/facilityReport/api/mutations/generated/DeleteSalaryPayment';
import { useChangeSalaryPaymentMutation } from 'pages/facilityReport/api/mutations/generated/ChangeSalaryPayment';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';

interface Props {
  sum: string;
  cause?: string;
  comment?: string;
}

export const useSurchargeForm = () => {
  const [createSalaryPayment, { loading }] = useCreateSalaryPaymentMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const [deleteSalaryPayment] = useDeleteSalaryPaymentMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const [changeSalaryPayment] = useChangeSalaryPaymentMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });
  const { shift, setShift } = useStore() as any;

  const initialValues: Props = {
    sum: '',
    cause: '',
    comment: '',
  };

  const validate = (values: Props) => {
    const errors: any = {};

    if (!values.sum) {
      errors.sum = 'Обязательное поле';
    }

    if (values.cause === '') {
      errors.cause = 'Обязательное поле';
    }
    if (values.comment === '') {
      errors.comment = 'Обязательное поле';
    }

    return errors;
  };

  const onSubmit = (values: Props) => {
    const newSum = values.sum ? Number(values.sum) : 0;

    return createSalaryPayment({
      variables: {
        input: {
          moderationId: shift.moderation.id,
          sum: newSum,
          cause: values.cause || '',
          comment: values.comment || '',
        },
      },
    })
      .then(res => {
        if (res.data?.createSalaryPayment) {
          const newPayment = {
            id: res.data.createSalaryPayment.id,
            sum: newSum,
            cause: values.cause || '',
            comment: values.comment || '',
            createdAt: res.data.createSalaryPayment.createdAt,
            updatedAt: res.data.createSalaryPayment.updatedAt,
          };

          setShift({
            ...shift,
            moderation: {
              ...shift.moderation,
              salaryPayments: [...(shift.moderation.salaryPayments || []), newPayment],
            },
          });
          successToast('Надбавка добавлена');
          return true;
        }
        return false;
      })
      .catch(e => {
        errorToast(errorHandler(e));
        return false;
      });
  };

  const handleDelete = (id: string) => {
    return deleteSalaryPayment({
      variables: {
        input: {
          id,
        },
      },
    })
      .then(() => {
        const newSalaryPayments = shift.moderation.salaryPayments.filter(
          (payment: any) => payment.id !== id
        );

        setShift({
          ...shift,
          moderation: {
            ...shift.moderation,
            salaryPayments: newSalaryPayments,
          },
        });
        successToast('Надбавка удалена');
        return true;
      })
      .catch(e => {
        errorToast(errorHandler(e));
        return false;
      });
  };

  const handleEdit = (id: string, values: Props) => {
    const newSum = values.sum ? Number(values.sum) : 0;

    return changeSalaryPayment({
      variables: {
        input: {
          id,
          sum: newSum,
        },
      },
    })
      .then(() => {
        setShift({
          ...shift,
          moderation: {
            ...shift.moderation,
            salaryPayments: shift.moderation.salaryPayments.map((payment: any) =>
              payment.id === id ? { ...payment, sum: newSum } : payment
            ),
          },
        });
        successToast('Надбавка изменена');
        return true;
      })
      .catch(e => {
        errorToast(errorHandler(e));
        return false;
      });
  };

  return { initialValues, onSubmit, validate, loading, handleDelete, handleEdit };
};
