import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSalaryPaymentMutationVariables = Types.Exact<{
  input: Types.DeleteSalaryPaymentInput;
}>;


export type DeleteSalaryPaymentMutation = { __typename?: 'Mutation', deleteSalaryPayment: boolean };


export const DeleteSalaryPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteSalaryPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteSalaryPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteSalaryPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteSalaryPaymentMutationFn = Apollo.MutationFunction<DeleteSalaryPaymentMutation, DeleteSalaryPaymentMutationVariables>;

/**
 * __useDeleteSalaryPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteSalaryPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalaryPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalaryPaymentMutation, { data, loading, error }] = useDeleteSalaryPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSalaryPaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalaryPaymentMutation, DeleteSalaryPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalaryPaymentMutation, DeleteSalaryPaymentMutationVariables>(DeleteSalaryPaymentDocument, options);
      }
export type DeleteSalaryPaymentMutationHookResult = ReturnType<typeof useDeleteSalaryPaymentMutation>;
export type DeleteSalaryPaymentMutationResult = Apollo.MutationResult<DeleteSalaryPaymentMutation>;
export type DeleteSalaryPaymentMutationOptions = Apollo.BaseMutationOptions<DeleteSalaryPaymentMutation, DeleteSalaryPaymentMutationVariables>;