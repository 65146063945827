import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { LockedPeriodFragmentDoc } from '../../fragments/generated/LockedPeriod.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLockedPeriodsQueryVariables = Types.Exact<{
  input: Types.GetLockedPeriodsInput;
}>;


export type GetLockedPeriodsQuery = { __typename?: 'Query', getLockedPeriods: { __typename?: 'GetLockedPeriodsModel', count: number, data: Array<{ __typename?: 'WorkSheetLockedPeriodEntity', id: string, erpId: string, startDate: any, endDate: any, createdAt: any, totalAmount: number, status: Types.WorkSheetLockedPeriodStatusEnum, countPayments: number, dateOfExecution?: any | null, facility?: { __typename?: 'Facility', id: string, name: string } | null, author?: { __typename?: 'User', id: string, firstname: string, lastname: string } | null }> } };


export const GetLockedPeriodsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLockedPeriods"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetLockedPeriodsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLockedPeriods"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LockedPeriod"}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}},...LockedPeriodFragmentDoc.definitions]} as unknown as DocumentNode;

/**
 * __useGetLockedPeriodsQuery__
 *
 * To run a query within a React component, call `useGetLockedPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLockedPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLockedPeriodsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLockedPeriodsQuery(baseOptions: Apollo.QueryHookOptions<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables> & ({ variables: GetLockedPeriodsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables>(GetLockedPeriodsDocument, options);
      }
export function useGetLockedPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables>(GetLockedPeriodsDocument, options);
        }
export function useGetLockedPeriodsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables>(GetLockedPeriodsDocument, options);
        }
export type GetLockedPeriodsQueryHookResult = ReturnType<typeof useGetLockedPeriodsQuery>;
export type GetLockedPeriodsLazyQueryHookResult = ReturnType<typeof useGetLockedPeriodsLazyQuery>;
export type GetLockedPeriodsSuspenseQueryHookResult = ReturnType<typeof useGetLockedPeriodsSuspenseQuery>;
export type GetLockedPeriodsQueryResult = Apollo.QueryResult<GetLockedPeriodsQuery, GetLockedPeriodsQueryVariables>;