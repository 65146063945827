import { Formik, Form } from 'formik';
import { useUpdateShiftPayment } from './hooks';
import { useStore } from 'store';
import { getFinalBonus } from 'pages/moderationReport/components/sidebar/dailyShifts/helper';
import clsx from 'clsx';
import { RU_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import Loader from 'ui/loader';
import SimpleInput from 'ui/input';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import SelectArrow from 'assets/icons/SelectArrow';
import SimpleSelect from 'ui/select';
import TextArea from 'ui/textArea';
import { moderationShiftReasons } from 'utils/helpers/lists/reasons';
import { useState, useEffect } from 'react';
import { useShiftConditions } from 'pages/moderationReport/lib/hooks';
import { UnitType } from 'generated/graphql';

const UpdateShiftPayment = () => {
  const { shift } = useStore() as any;
  const { initialValues, onSubmit, validate, loading } = useUpdateShiftPayment();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const { unitSymbol, factValue } = useShiftConditions(shift || {});
  const [formKey, setFormKey] = useState(shift?.id);

  useEffect(() => {
    if (formKey !== shift?.id) {
      setFormKey(shift?.id);
      setIsCommentOpen(false);
      if (shift?.moderation?.cause || shift?.moderation?.comment) {
        setIsCommentOpen(true);
      }
    }
  }, [shift?.id, formKey, shift?.moderation?.cause, shift?.moderation?.comment]);

  return (
    <Formik
      key={formKey}
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize={false}
    >
      {({ values, setFieldValue, handleChange, touched, errors }) => (
        <Form className={'flex flex-col shadow-smena rounded-lg mb-4'}>
          <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>Оплата</p>

          <div className="border-b">
            <div className="grid grid-cols-2 p-4 gap-y-4">
              <div className="col-span-1 grid gap-y-2">
                <span className="Subtitle2">Факт</span>
                <span className="Table-small text-smena_text-secondary">
                  {factValue || 0}
                  {unitSymbol}
                </span>
              </div>

              {unitSymbol === 'ч.' ? (
                <TimePickerInput
                  label="Ред. факт"
                  className="col-span-1"
                  popupClassName="fixed"
                  value={values.time}
                  onChange={time => {
                    setFieldValue('time', time);
                  }}
                  error={touched.time && errors.time ? errors.time : ''}
                />
              ) : (
                <SimpleInput
                  divClassName="col-span-1"
                  label={unitSymbol === '%' ? 'Ред. процент' : 'Ред. заказы'}
                  onChange={handleChange}
                  float
                  value={values.value}
                  type="text"
                  name="value"
                  error={touched.value && errors.value ? errors.value : ''}
                />
              )}

              <div className="col-span-1 flex flex-col gap-y-2">
                <span className="Subtitle2">Ставка</span>
                <span className="Table-small text-smena_text-secondary">
                  {shift?.moderation?.hourRate ? `${shift?.moderation?.hourRate}` : `${shift?.hourRate}`}
                  {shift?.unit === UnitType.Point || shift?.unit === UnitType.Piece ? ' ₽/%' : ' ₽/час'}
                </span>
              </div>
              <SimpleInput
                divClassName="col-span-1"
                label="Ред. ставку"
                onChange={handleChange}
                float
                value={values.hourRate}
                type="text"
                name="hourRate"
                placeholder="Введите ставку"
                error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
              />
            </div>
          </div>

          <div className="Subtitle2 px-4 py-2">
            К оплате:{' '}
            <span className="Body2 text-smena_text-secondary pr-4">
              {(shift?.moderation?.cost || shift?.cost) + getFinalBonus(shift)} ₽
            </span>
          </div>

          <div className="grid grid-cols-2 gap-y-1 p-4 border-y border-smena_gray-30">
            <label className={clsx('rounded-l-lg radio-block', { 'radio-block_active': values.toPayment === true })}>
              <input
                type="radio"
                name="toPayment"
                className="radio radio_approve"
                checked={values.toPayment === true}
                onChange={() => setFieldValue('toPayment', true)}
                disabled={loading}
              />
              К оплате
            </label>
            <label className={clsx('radio-block rounded-r-lg', { 'radio-block_active': values.toPayment === false })}>
              <input
                type="radio"
                name="toPayment"
                className="radio radio_reject"
                checked={values.toPayment === false}
                onChange={() => setFieldValue('toPayment', false)}
                disabled={loading}
              />
              Без оплаты
            </label>
          </div>

          <div className="col-span-4 px-4 pb-3 border-b border-smena_gray-30">
            <div
              className="flex items-center gap-x-2 cursor-pointer mt-3"
              onClick={() => setIsCommentOpen(prevState => !prevState)}
            >
              <span className="Caption-numbers text-primary">Оставить комментарий</span>
              <span className={clsx('transform transition-transform', { 'rotate-180': isCommentOpen })}>
                <SelectArrow className="text-primary" />
              </span>
            </div>

            {isCommentOpen && (
              <div className="flex flex-col gap-y-2">
                <SimpleSelect
                  divClassName="relative"
                  label="Причина редактирования"
                  onChange={value => {
                    setFieldValue('cause', value);
                  }}
                  value={values.cause}
                  placeholder="Все причины"
                  options={moderationShiftReasons}
                />
                <TextArea
                  label={'Комментарий (если нужно)'}
                  onChange={handleChange}
                  placeholder="Оставьте комментарий"
                  value={values.comment}
                  name={'comment'}
                />
              </div>
            )}
          </div>

          <div className="flex justify-between items-center px-4 py-4">
            <div className="Caption text-smena_text-secondary flex gap-x-1">
              <span>Посл. изменения:</span>
              <span>{dayjs(shift?.updatedAt).format(RU_DATE)}</span>
            </div>
            <button type="submit" className="btn-primary_small" disabled={loading}>
              {loading ? (
                <div className="flex items-center gap-x-2 text-smena_gray-30">
                  <Loader />
                  <span>Сохранение</span>
                </div>
              ) : (
                'Сохранить'
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateShiftPayment;
