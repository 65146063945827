import React from 'react';

const BMark: React.FC = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="8.5" height="8.5" rx="1.25" fill="#1B984D" />
      <rect x="0.75" y="0.75" width="8.5" height="8.5" rx="1.25" stroke="white" strokeWidth="0.5" />
      <path
        d="M6.53906 2.15625V2.9375H3.89453V7.84375H2.91797V2.15625H6.53906ZM3.59766 4.33984H5.02734C5.45964 4.33984 5.82943 4.41276 6.13672 4.55859C6.44401 4.70182 6.67839 4.90365 6.83984 5.16406C7.0013 5.42448 7.08203 5.72786 7.08203 6.07422C7.08203 6.33464 7.03646 6.57292 6.94531 6.78906C6.85417 7.00521 6.72005 7.19271 6.54297 7.35156C6.36589 7.50781 6.14974 7.62891 5.89453 7.71484C5.64193 7.80078 5.35286 7.84375 5.02734 7.84375H2.91797V2.15625H3.90234V7.06641H5.02734C5.27474 7.06641 5.47786 7.02083 5.63672 6.92969C5.79557 6.83594 5.91276 6.71354 5.98828 6.5625C6.06641 6.41146 6.10547 6.24609 6.10547 6.06641C6.10547 5.89193 6.06641 5.73307 5.98828 5.58984C5.91276 5.44661 5.79557 5.33203 5.63672 5.24609C5.47786 5.16016 5.27474 5.11719 5.02734 5.11719H3.59766V4.33984Z"
        fill="white"
      />
    </svg>
  );
};

export default BMark;
