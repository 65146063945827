import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeSalaryPaymentMutationVariables = Types.Exact<{
  input: Types.ChangeSalaryPaymentInput;
}>;


export type ChangeSalaryPaymentMutation = { __typename?: 'Mutation', changeSalaryPayment: { __typename?: 'ModerationSalaryPaymentEntity', id: string, createdAt: any, updatedAt?: any | null } };


export const ChangeSalaryPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeSalaryPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeSalaryPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeSalaryPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode;
export type ChangeSalaryPaymentMutationFn = Apollo.MutationFunction<ChangeSalaryPaymentMutation, ChangeSalaryPaymentMutationVariables>;

/**
 * __useChangeSalaryPaymentMutation__
 *
 * To run a mutation, you first call `useChangeSalaryPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSalaryPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSalaryPaymentMutation, { data, loading, error }] = useChangeSalaryPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSalaryPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSalaryPaymentMutation, ChangeSalaryPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSalaryPaymentMutation, ChangeSalaryPaymentMutationVariables>(ChangeSalaryPaymentDocument, options);
      }
export type ChangeSalaryPaymentMutationHookResult = ReturnType<typeof useChangeSalaryPaymentMutation>;
export type ChangeSalaryPaymentMutationResult = Apollo.MutationResult<ChangeSalaryPaymentMutation>;
export type ChangeSalaryPaymentMutationOptions = Apollo.BaseMutationOptions<ChangeSalaryPaymentMutation, ChangeSalaryPaymentMutationVariables>;