import { DayjsOrNull } from 'interfaces/CustomTypes';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useChangeShiftModerationMutation } from 'pages/facilityRequests/api/mutations/generated/ChangeShiftModeration';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { successToast, errorToast } from 'utils/helpers/notify';
import dayjs from 'dayjs';
import { padZeroTime } from 'utils/helpers/timeObject';
import { useShiftConditions } from 'pages/moderationReport/lib/hooks';
import { useApolloClient } from '@apollo/client';
import { UnitType } from 'generated/graphql';

interface Props {
  toPayment: boolean | undefined;
  cause?: string;
  comment?: string;
  hourRate: string;
  duration: number;
  time: DayjsOrNull;
  value: string;
}

export const useUpdateShiftPayment = () => {
  const { shift, setShift } = useStore() as any;
  const { duration } = useShiftConditions(shift || {});
  const client = useApolloClient();
  const [changeShift, { loading }] = useChangeShiftModerationMutation();

  const initialValues: Props = {
    toPayment:
      shift?.moderation?.toPayment === true ? true : shift?.moderation?.toPayment === false ? false : undefined,
    duration: 0,
    hourRate: shift?.moderation?.hourRate ? String(shift.moderation.hourRate) : shift?.hourRate,
    cause: shift?.moderation?.cause || '',
    comment: shift?.moderation?.comment || '',
    value: shift?.moderation?.value ? String(shift.moderation.value) : '0',
    time: shift?.moderation?.duration
      ? dayjs()
          .set('hour', Math.floor(shift.moderation.duration / 60))
          .set('minutes', shift.moderation.duration % 60)
      : dayjs()
          .set('hour', Number(padZeroTime(duration).hours))
          .set('minutes', Number(padZeroTime(duration).minutes)),
  };

  const validate = () => {
    const errors: any = {};

    return errors;
  };

  const onSubmit = (values: Props) => {
    const durationShift = values.time ? values.time.get('hours') * 60 + values.time.get('minutes') : null;
    const newHourRate = values.hourRate ? Number(values.hourRate) : 0;
    const newDuration = durationShift !== null ? durationShift : Number(shift.durationPlan);
    changeShift({
      variables: {
        moderation: {
          shiftId: shift.id,
          hourRate: newHourRate,
          value: Number(values.value),
          toPayment: values.toPayment,
          comment: values.comment,
          cause: values.cause,
          duration: newDuration,
        },
      },
    })
      .then(res => {
        if (res.data?.changeShiftModeration) {
          setShift({
            ...shift,
            durationFact: newDuration,
            moderation: {
              ...shift.moderation,
              toPayment: values.toPayment,
              cause: values.cause,
              comment: values.comment,
              hourRate: newHourRate,
              duration: newDuration,
              value: Number(values.value),
              cost: shift?.unit === UnitType.Point || shift?.unit === UnitType.Piece
                ? shift?.unit === UnitType.Point 
                  ? Math.round(newHourRate * Number(values.value))
                  : Math.round(res?.data?.changeShiftModeration?.moderation?.cost || shift?.moderation?.cost || shift?.cost)
                : Math.round((newHourRate * newDuration) / 60),
            },
            cost: shift?.unit === UnitType.Point || shift?.unit === UnitType.Piece
              ? shift?.unit === UnitType.Point 
                ? Math.round(newHourRate * Number(values.value))
                : Math.round(res?.data?.changeShiftModeration?.moderation?.cost || shift?.moderation?.cost || shift?.cost)
              : Math.round((newHourRate * newDuration) / 60),
          });
          client.refetchQueries({
            include: [GetSupervisorWorkSheetDocument],
          });
          successToast('Все изменения сохранены');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  return { initialValues, onSubmit, validate, loading };
};
