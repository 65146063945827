import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LockWorkSheetPeriodMutationVariables = Types.Exact<{
  input: Types.LockWorkSheetPeriodInput;
}>;


export type LockWorkSheetPeriodMutation = { __typename?: 'Mutation', lockWorkSheetPeriod: boolean };


export const LockWorkSheetPeriodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LockWorkSheetPeriod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LockWorkSheetPeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lockWorkSheetPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type LockWorkSheetPeriodMutationFn = Apollo.MutationFunction<LockWorkSheetPeriodMutation, LockWorkSheetPeriodMutationVariables>;

/**
 * __useLockWorkSheetPeriodMutation__
 *
 * To run a mutation, you first call `useLockWorkSheetPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockWorkSheetPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockWorkSheetPeriodMutation, { data, loading, error }] = useLockWorkSheetPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLockWorkSheetPeriodMutation(baseOptions?: Apollo.MutationHookOptions<LockWorkSheetPeriodMutation, LockWorkSheetPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LockWorkSheetPeriodMutation, LockWorkSheetPeriodMutationVariables>(LockWorkSheetPeriodDocument, options);
      }
export type LockWorkSheetPeriodMutationHookResult = ReturnType<typeof useLockWorkSheetPeriodMutation>;
export type LockWorkSheetPeriodMutationResult = Apollo.MutationResult<LockWorkSheetPeriodMutation>;
export type LockWorkSheetPeriodMutationOptions = Apollo.BaseMutationOptions<LockWorkSheetPeriodMutation, LockWorkSheetPeriodMutationVariables>;