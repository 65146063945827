import Plus from 'assets/icons/plus';
import Minus from 'assets/icons/iconMinus';
import { useState } from 'react';
import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import { tabelSurchargeReasons } from 'utils/helpers/lists/reasons';
import { useSurchargeForm } from './hooks';
import { Form, Formik } from 'formik';
import { useStore } from 'store';
import Loader from 'ui/loader';
import Modal from 'ui/modal';
import TextArea from 'ui/textArea';
import ChevronDown from 'assets/icons/chevron-down';

const Surcharge = () => {
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const { shift } = useStore() as any;
  const { initialValues, onSubmit, validate, loading, handleDelete, handleEdit } = useSurchargeForm();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<string | null>(null);

  const handleEditClick = (index: number) => {
    setEditIndex(index === editIndex ? null : index);
  };

  const handleDeleteClick = (id: string) => {
    setSelectedPaymentId(id);
    setShowDeleteModal(true);
  };

  const handleCreateSuccess = () => {
    setShow(false);
  };

  const confirmDelete = () => {
    if (selectedPaymentId) {
      handleDelete(selectedPaymentId)
        .then((success) => {
          if (success) {
            setEditIndex(null);
          }
          setShowDeleteModal(false);
          setSelectedPaymentId(null);
        });
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedPaymentId(null);
  };

  return (
    <div className="gap-y-3 border-b border-smena_gray-30 shadow-smena rounded-lg mb-4">
      <Modal
        type="delete"
        title="Удалить надбавку"
        open={showDeleteModal}
        onOk={confirmDelete}
        onCancel={cancelDelete}
        okText="Удалить"
        cancelText="Отмена"
      >
        <p>Вы уверены, что хотите удалить надбавку?</p>
      </Modal>
      <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>Надбавки</p>

      {shift?.moderation?.salaryPayments?.length > 0 && (
        <div>
          {shift.moderation.salaryPayments.map((payment: any, index: number) => (
            <div key={index}>
              <div className="text-smena_text Subtitle2 px-4 py-2 border-b flex items-center gap-2 cursor-pointer" onClick={() => handleEditClick(index)}>
                <div>
                  {payment.cause}: <span className="Caption text-smena_text-secondary">{payment.sum} ₽</span>
                </div>
                <ChevronDown className={`text-smena_text-secondary transition-transform duration-200 ${editIndex === index ? 'rotate-180' : ''}`} />
              </div>
              {editIndex === index && (
                <Formik
                  initialValues={{ sum: payment.sum }}
                  onSubmit={(values) => {
                    if (editIndex !== null) {
                      handleEdit(shift.moderation.salaryPayments[editIndex].id, values)
                        .then((success) => {
                          if (success) {
                            setEditIndex(null);
                          }
                        });
                    } else {
                      onSubmit(values);
                    }
                  }}
                  validate={validate}
                  enableReinitialize={true}
                >
                  {({ values, touched, errors, handleChange }) => (
                    <Form>
                      <div className="px-4">
                        <SimpleInput
                          divClassName="mb-3"
                          label="Сумма"
                          onChange={handleChange}
                          regText
                          value={values.sum}
                          placeholder="Введите сумму"
                          type="text"
                          name="sum"
                          error={touched.sum && errors.sum ? errors.sum : ''}
                        />

                        <div className="flex items-center justify-end">
                          <button type="button" className="btn-reject_small mr-2" onClick={() => handleDeleteClick(payment.id)}>
                            Удалить
                          </button>
                          <button type="submit" className="btn-primary_small" disabled={loading}>
                            {loading ? (
                              <div className="flex items-center gap-x-2 text-smena_gray-30">
                                <Loader />
                                <span>Сохранение</span>
                              </div>
                            ) : (
                              'Изменить'
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          ))}
        </div>
      )}

      <button
        className={'Button2 text-primary flex items-center gap-x-[10px] px-4 py-2'}
        type="button"
        onClick={() => setShow(prevState => !prevState)}
      >
        {show ? <Minus className={'text-primary'} /> : <Plus className={'text-primary'} />}
        <span className="Button1">Добавить надбавку</span>
      </button>

      {show && (
        <Formik initialValues={initialValues} onSubmit={(values) => {
          if (editIndex !== null) {
            handleEdit(shift.moderation.salaryPayments[editIndex].id, values)
              .then((success) => {
                if (success) {
                  setEditIndex(null);
                }
              });
          } else {
            onSubmit(values)
              .then((success) => {
                if (success) {
                  handleCreateSuccess();
                }
              });
          }
        }} validate={validate} enableReinitialize={true}>
          {({ values, setFieldValue, touched, errors, handleChange }) => {
            return (
              <Form>
                <div className="px-4 pb-2">
                  <SimpleInput
                    divClassName="mb-3"
                    label="Сумма"
                    onChange={handleChange}
                    regText
                    value={values.sum}
                    placeholder="Введите сумму"
                    type="text"
                    name="sum"
                    error={touched.sum && errors.sum ? errors.sum : ''}
                  />

                  <div className="flex flex-col gap-y-3 pb-2">
                    <SimpleSelect
                      divClassName="relative"
                      label="Причина надбавки"
                      onChange={value => {
                        setFieldValue('cause', value);
                      }}
                      value={values.cause}
                      placeholder="Все причины"
                      options={tabelSurchargeReasons}
                      popupClassName={'fixed'}
                      error={touched.cause && errors.cause ? errors.cause : ''}
                    />

                    <TextArea
                      style={{
                        height: '100px',
                      }}
                      placeholder={'Комментарий'}
                      label={'Оставьте комментарий'}
                      onChange={handleChange}
                      value={values.comment}
                      name={'comment'}
                      error={touched.comment && errors.comment ? errors.comment : ''}
                    />
                  </div>

                  <div className="flex items-center justify-end">
                    <button type="submit" className="btn-primary_small" disabled={loading}>
                      {loading ? (
                        <div className="flex items-center gap-x-2 text-smena_gray-30">
                          <Loader />
                          <span>Сохранение</span>
                        </div>
                      ) : (
                        'Сохранить'
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default Surcharge;
