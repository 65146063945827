import { WorkSheetLockedPeriodStatusEnum } from 'generated/graphql';

/**
 * Текстовые метки для статусов периодов
 */
export const periodStatusLabels: Record<WorkSheetLockedPeriodStatusEnum, string> = {
    [WorkSheetLockedPeriodStatusEnum.Rejected]: 'Отклонён',
    [WorkSheetLockedPeriodStatusEnum.InProgress]: 'В процессе',
    [WorkSheetLockedPeriodStatusEnum.Sent]: 'Отправлен',
    [WorkSheetLockedPeriodStatusEnum.Approved]: 'Утверждён',
    [WorkSheetLockedPeriodStatusEnum.Executed]: 'Исполнен',
    [WorkSheetLockedPeriodStatusEnum.Cancelled]: 'Отменён',
};

/**
 * CSS классы для отображения статусов периодов в виде бейджей
 */
export const periodStatusBadgeClasses: Record<WorkSheetLockedPeriodStatusEnum, string> = {
    [WorkSheetLockedPeriodStatusEnum.Rejected]: 'bg-smena_red-extra_light text-smena_red',
    [WorkSheetLockedPeriodStatusEnum.InProgress]: 'bg-smena_yellow-extra_light text-smena_orange-dark',
    [WorkSheetLockedPeriodStatusEnum.Sent]: 'bg-smena_purple-extra_light text-smena_purple-dark',
    [WorkSheetLockedPeriodStatusEnum.Approved]: 'bg-smena_bb-background text-primary',
    [WorkSheetLockedPeriodStatusEnum.Executed]: 'bg-smena_green-extra_light text-smena_green-dark',
    [WorkSheetLockedPeriodStatusEnum.Cancelled]: 'bg-smena_gray-5 text-smena_text-secondary',
};

/**
 * Компонент бейджа статуса периода
 * @param status Статус периода
 * @returns Объект с текстом и CSS классом для статуса
 */
export const getPeriodStatusBadge = (status: WorkSheetLockedPeriodStatusEnum | string | null | undefined) => {
    if (!status) {
        return {
            text: 'Неизвестно',
            className: 'bg-smena_gray-5 text-smena_text-secondary'
        };
    }

    const statusEnum = status as WorkSheetLockedPeriodStatusEnum;

    return {
        text: periodStatusLabels[statusEnum] || String(status),
        className: periodStatusBadgeClasses[statusEnum] || 'bg-smena_gray-5 text-smena_text-secondary'
    };
}; 