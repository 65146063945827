import MainLayout from 'components/layout/main';
import FacilityReportTable from './components/table';
import { useFacilityTitle } from 'pages/facilityVacancies/hooks/pageProps';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import FacilityReportFilter from './components/facilityReportFilter';
import { useParams } from 'react-router';
import { useGetSupervisorWorkSheetQuery } from './api/queries/generated/GetSupervisorWorkSheet';
import { useFacilityReportStore } from './useFacilityReportStore/useFacilityReportStore';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import NewShift from './components/sidebar/newShift';
import StopCooperationSidebar from './components/sidebar/stopCooperation';
import Shedule from './components/sidebar/shedule';
import EditShiftSidebar from './components/sidebar/editShift';
import StopCooperationAtAllFacilitiesPopup from './components/popups/components/stopCooperationAtAllFacilitiesPopup';
import StopCooperationPopup from './components/popups/components/stopCooperationPopup';
import FacilityReportHeader from './components/facilityReportHeader/index.';
import NewModerationShift from './components/sidebar/newModerationShift';
import EditModerationShift from './components/sidebar/editModerationShift';
import { useEffect } from 'react';

const FacilityReport: React.FC = () => {
  const [params] = useSearchParams();

  const title = useFacilityTitle();
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();

  const { facilityId } = useParams();
  const {
    positionIds,
    userIds,
    doNotShowShifts,
    isFilterLoaded,
    order,
    orderBy,
    selectedTimezone,
    setLockedPeriods
  } = useFacilityReportStore();

  const dateParam = params.get('date');

  const { data, loading } = useGetSupervisorWorkSheetQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        order,
        orderBy,
        facilityId: facilityId || '',
        month: dayjs(dateParam).month() + 1 || dayjs().month() + 1,
        year: dayjs(dateParam).year() || dayjs().year(),
        positionIds,
        userIds,
        doNotShowShifts,
        timezone: selectedTimezone,
      },
    },
    skip: !facilityId || !isFilterLoaded,
  });

  useEffect(() => {
    if (data?.getSupervisorWorkSheet.lockedPeriods) {
      setLockedPeriods(data.getSupervisorWorkSheet.lockedPeriods);
    }
  }, [data?.getSupervisorWorkSheet.lockedPeriods, setLockedPeriods]);

  return (
    <MainLayout title={title} breadCrumbs={breadCrumbs} headerMenu={headerMenu}>
      <div className="rounded-lg shadow-smena bg-smena_white flex-1">
        <FacilityReportHeader />
        <FacilityReportFilter filterData={data?.getSupervisorWorkSheet.filterData} />
        <FacilityReportTable
          data={data?.getSupervisorWorkSheet.data}
          loading={loading}
          lockedPeriods={data?.getSupervisorWorkSheet.lockedPeriods}
        />
      </div>

      <StopCooperationSidebar />
      <StopCooperationPopup />
      <StopCooperationAtAllFacilitiesPopup />

      <NewModerationShift />
      <EditModerationShift />

      <NewShift />
      <Shedule />
      <EditShiftSidebar />
    </MainLayout>
  );
};

export default FacilityReport;
